@import "../../../Assets/scss/abstracts/variables";

.tile-container {
    width: 100%;
    max-width: $pageWidth;
    margin: 25px auto 0 auto;
    position: relative;

    .tile-title {
        width: calc(100% - 150px);
        margin: 0 75px 10px;
        font-family: $font-bold;
        font-weight: 700;
        border-left: 1px solid #fff;
        position: relative;

        label {
            cursor: pointer;
            position: absolute;
            left: -35px;

            svg {
                width: 4px;
                height: 13px;
                align-self: center;
                transition: transform .3s, margin .3s;

                .st0 {
                    stroke: #fff;
                }
            }
        }

        .view-mode {
            width: 15px;
            height: 15px;
            position: absolute;
            left: -25px;
            top: 1px;
            cursor: pointer;

            svg {
                transition: transform .3s;
            }

            &.vertical svg {
                transform: rotate(90deg);
            }
        }

        > span {
            margin: 0 0 0 10px;
        }
    }

    input:checked {
        + div {
            > label svg {
                transform: rotate(-90deg) translatey(-5px);
            }

            + div {
                max-height: 0 !important;
                overflow: hidden;
            }
        }
    }

    .tile-content {
        width: 100%;
        height: 160px;
        position: relative;
        max-height: 160px;
        transition: max-height .3s;

        .loader-container {
            z-index: 2;
        }

        .load-more-container {
            display: none;
        }

        .tile-pagination {
            position: absolute;
            right: 80px;
            bottom: calc(100% + 10px);
            display: flex;
            opacity: 0;
            transition: opacity .3s;

            > div {
                height: 2px;
                width: 12px;
                margin: 0 0 0 2px;
                background-color: rgba(255,255,255,.5);
                position: relative;
                transition: background-color .3s;

                &.active {
                    background-color: rgba(255,255,255,1);
                }
            }
        }

        .arrow {
            position: absolute;
            width: 75px;
            height: calc(100% - 9px);
            display: flex;
            align-items: center;
            top: 0;
            opacity: 0;
            z-index: -1;
            transition: opacity .3s;
            background-color: rgba(78, 78, 78, 0.3);

            &.active {
                opacity: 1 !important;
                z-index: 5;
                transition: background-color .3s;

                &:hover {
                    background-color: rgba(78,78,78,.5);

                    svg {
                        transform: scale(1.2);

                        .st0 {
                            stroke: rgba(255,255,255,1);
                        }
                    }
                }
            }

            svg {
                cursor: pointer;
                height: 50%;
                transition: transform .3s, opacity .3s;
                opacity: 0;

                .st0 {
                    stroke: rgba(255,255,255,.3);
                    transition: stroke .3s;
                }
            }

            &.left {
                left: 0;
            }

            &.right {
                right: 0;
            }
        }

        &:hover .arrow svg, .tile-pagination {
            opacity: 1;
        }


        .tile-content-inner {
            max-height: 160px;
            height: 160px;
            width: calc(100% - 150px) !important;
            margin: 0 75px 0 75px;
            white-space: nowrap;
            display: block;
            transition: transform .75s, margin .3s;
            position: relative;

            &.single {
                transition: transform .4s, margin .3s;
            }

            &.bottom-row {
                margin: 30px 75px 0 75px;
            }

            &::-webkit-scrollbar {
                display: none;
            }

            .tile {

                &.hidden {
                    width: 0;
                    margin: 0;
                    overflow: hidden;

                    .tile-inner .content > * {
                        display: none;
                    }
                }
                /* &:nth-child(4n + 1) .tile-inner:hover {
                    margin: -9% 0 0 0%;
                }


                &:nth-child(4n) .tile-inner:hover {
                    margin: -9% 0 0 -30%;
                }*/
            }
            /*
            &.Single25 {

                .tile {
                    &:nth-child(4n + 1), &:nth-child(4n) {
                        .tile-inner:hover {
                            margin: -9% 0 0 -14%;
                        }
                    }
                }

                .tile:nth-child(4n + 2) .tile-inner:hover {
                    margin: -9% 0 0 0%;
                }


                .tile:nth-child(4n + 1) .tile-inner:hover {
                    margin: -9% 0 0 -30%;
                }
            }

            &.Single50 {

                .tile {
                    &:nth-child(4n + 1), &:nth-child(4n) {
                        .tile-inner:hover {
                            margin: -9% 0 0 -14%;
                        }
                    }
                }

                .tile:nth-child(4n + 3) .tile-inner:hover {
                    margin: -9% 0 0 0%;
                }

                .tile:nth-child(4n + 2) .tile-inner:hover {
                    margin: -9% 0 0 -30%;
                }
            }

            &.Single75 {

                .tile {
                    &:nth-child(4n + 1), &:nth-child(4n) {
                        .tile-inner:hover {
                            margin: -9% 0 0 -14%;
                        }
                    }
                }

                .tile:nth-child(4n + 4) .tile-inner:hover {
                    margin: -9% 0 0 0%;
                }


                .tile:nth-child(4n + 3) .tile-inner:hover {
                    margin: -9% 0 0 -30%;
                }
            }*/
        }

        &.sliding .tile {
            overflow: hidden;
        }

        &.vertical {
            max-height: none;
            height: auto;
            min-height: 380px;

            .load-more-container {
                display: flex;
            }

            .arrow, .tile-pagination {
                display: none !important;
            }

            .tile-content-inner {
                transform: none !important;
                position: relative;
                height: auto;
                max-height: inherit;
                min-height: 160px;
                display: flex !important;
                flex-wrap: wrap;
                margin: 0 75px 0 75px;

                .tile {
                    height: 160px;

                    &:not(.hidden) {
                        margin: 0 5px 30px 0;
                    }

                    &.loading {
                        display: none;
                    }
                }
            }
        }
    }

    &:hover .tempViewIcon {
        opacity: 1 !important;
    }

    &.knowledge-explorer {
        &.small-explorer {
            > .tile-content:not(.vertical) {
                height: 160px !important;
                max-height: 160px !important;
            }

            > .tile-content.vertical {
                min-height: 190px;
            }
        }

        &:not(.small-explorer) {
            > .tile-content {
                height: 346px;
                max-height: 346px;





                .tile-content-inner {
                    position: relative;
                    z-index: 2;

                    &.bottom-row {
                        z-index: 1;
                        transition: transform .75s, margin .3s;
                    }
                }

                .arrow {
                    height: 151px;

                    &.right {
                        top: 190px;
                    }
                }

                &.vertical {
                    max-height: none;
                    height: auto;
                }
            }

            &.started .tile-content .tile-content-inner .tile {
                transition: width .75s, margin .75s;
            }

            .shadow-tile {
                height: 151px;

                &.left {
                }

                &.right {
                    bottom: 5px;
                }
            }
        }
    }

    &:nth-last-child(1) .tile-content-inner {
        z-index: 1;
    }

    &:nth-last-child(2) .tile-content-inner {
        z-index: 2;
    }

    &:nth-last-child(3) .tile-content-inner {
        z-index: 3;
    }

    &:nth-last-child(4) .tile-content-inner {
        z-index: 4;
    }

    &:nth-last-child(5) .tile-content-inner {
        z-index: 5;
    }
}

.tile-content-inner .tile.fixed {


    &:nth-child(4n + 1) .tile-inner {
        margin: -9% 0 0 0%;
    }

    &:nth-child(4n) .tile-inner {
        margin: -9% 0 0 -30%;
    }
}

.content-main > div:not(.context-open) div:not(.slide-inner):not(.adaptivecards-container-inner) .tile-content-inner {

    > .tile {
        &:nth-child(4n + 1) .tile-inner:hover {
            margin: -9% 0 0 0%;
        }


        &:nth-child(4n) .tile-inner:hover {
            margin: -9% 0 0 -30%;
        }
    }

    &.Single25 {

        .tile {
            &:nth-child(4n + 1), &:nth-child(4n) {
                .tile-inner:hover {
                    margin: -9% 0 0 -14%;
                }
            }
        }

        .tile:nth-child(4n + 2) .tile-inner:hover {
            margin: -9% 0 0 0%;
        }


        .tile:nth-child(4n + 1) .tile-inner:hover {
            margin: -9% 0 0 -30%;
        }
    }

    &.Single50 {

        .tile {
            &:nth-child(4n + 1), &:nth-child(4n) {
                .tile-inner:hover {
                    margin: -9% 0 0 -14%;
                }
            }
        }

        .tile:nth-child(4n + 3) .tile-inner:hover {
            margin: -9% 0 0 0%;
        }

        .tile:nth-child(4n + 2) .tile-inner:hover {
            margin: -9% 0 0 -30%;
        }
    }

    &.Single75 {

        .tile {
            &:nth-child(4n + 1), &:nth-child(4n) {
                .tile-inner:hover {
                    margin: -9% 0 0 -14%;
                }
            }
        }

        .tile:nth-child(4n + 4) .tile-inner:hover {
            margin: -9% 0 0 0%;
        }


        .tile:nth-child(4n + 3) .tile-inner:hover {
            margin: -9% 0 0 -30%;
        }
    }
}

.content-main > .context-open div:not(.slide-inner):not(.adaptivecards-container-inner) .tile-content-inner {


    > .tile.fixed {
        &:nth-child(4n + 1) .tile-inner {
            margin: -9% 0 0 0%;
        }


        &:nth-child(4n) .tile-inner {
            margin: -9% 0 0 -30%;
        }
    }
}
