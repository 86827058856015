%font-bold {
    font-family: $font-bold;
    font-weight: 700;
}

%font-light {
    font-family: $font-light;
    font-weight: 300;
}

html {
    font-family: $font-regular,Arial,sans-serif;
    font-size: 16px;
    line-height: 20px;
    color: $primary-color;
    height: 100%;
    overflow: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    word-break: break-word;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    margin: 0;
    min-width: 320px;
    height: 100%;
    background-image: url("../../images/theme/night/bg_nightmode.png");
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    position: relative;
    overflow-x: hidden;

    strong, b {
        font-family: $font-bold;
        font-weight: 700;
    }

    p {
        width: 100%;
    }

    ul {
        margin: 0;
    }

    hr {
        width: 100%;
        border: none;
        border-bottom: 1px solid rgba(255,255,255,.25);
        margin-top: 0;
    }
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background-color: $themeBG;
    background-clip: content-box; // this is important
    border-radius: 9px;
    border: 1px solid rgba(255,255,255,.75);
}

::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 9px;
    border: 3px solid transparent;
    background-clip: content-box;
}

a {
    text-decoration: none;
    font-weight: normal;
    color: inherit;
    position: relative;
    @extend %font-bold;
    transition: color .3s;
    /*&:hover {
        color: $secondary-color
    }*/
}

p {
    width: 100%;
    margin: 0;
}

svg {
    width: 100%;
    height: 100%;
}

.bold {
    @extend %font-bold;
}

.pointer {
    cursor: pointer;
}

.ng-hide {
    display: none;
}

#root {
    width: 100%;
    min-height: 100%;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 1000px;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url("../../images/theme/bg-dark.png");
        background-position: center;
        background-size: cover;
        background-repeat: repeat-y;
    }

    background-image: url("../../images/theme/bg-dark.png");
    background-position: center 100px;
    background-size: contain;
    background-repeat: repeat-x;
}

.layout-container {
    width: 100%;
    height: auto;
    max-height: calc(100% - 106px);
    overflow-x: hidden;
    margin: 0 0 42px 0;
    z-index: 1;
    position: relative;
}

/* ---------------------------- main page body -----------------------------------------*/
.content-main {
    position: relative;
    width: 100%;
    height: auto;
    /*overflow: hidden;*/
}

/* ---------------------------- main elements -----------------------------------------*/
.content-wrapper {
    /*max-width: 1440px;*/
    width: calc(100% - 200px);
    height: auto;
    margin: 0 auto;

    > .slider {
        > div {
            > .model-container.zooming {
                position: static;

                > .active {
                    > .slide-container.square.magnify {
                        position: fixed;
                    }
                }
            }
        }
    }
}


/* ---------------------- login/register box ---------------------- */

input {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    outline: none;
    border: none;
    padding: 0;
    font-size: 16px;
    font-family: $font-regular;
}

input:-webkit-autofill,
input:-internal-autofill-selected {
    color: transparent;
}

select {
    width: 100%;
    border: none;
    -webkit-appearance: none;
    font-size: 16px;
    line-height: 20px;
    font-family: $font-regular;

    &:focus {
        outline: none;
    }
}

button {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    outline: none;
    border: none;
    padding: 0;
}

ul {
    padding-left: 16px;

    li {
        width: 100%;
    }
}

/* ---------------------- elements ---------------------- */
.button {
    text-align: center;
    width: auto;
    min-width: 100px;
    padding: 5px 25px;
    background-color: #fff;
    border: 1px solid #ccc;
    @extend %font-bold;
    cursor: pointer;
    color: $primary-color;
}

.checkbox-container {
    width: 52px;
    height: 22px;
    border: 1px solid rgba(255,255,255,.25);
    border-radius: 23px;
    cursor: pointer;
    margin: -2px 12px 0;

    input:checked + span:before {
        left: 0;
    }

    span {
        position: relative;
        margin: 2px;
        width: 48px;
        height: 18px;
        border-radius: 20px;
        overflow: hidden;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: calc(100% - 20px);
            width: 18px;
            height: 18px;
            border-radius: 20px;
            background-color: rgba(255,255,255,.25);
            transition: left .3s;
        }
    }
}
