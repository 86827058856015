@import "../../../Assets/scss/abstracts/variables";

.tile {
    height: 100%;
    margin: 0 5px 0 0;
    position: relative;
    width: calc(25% - 5px);
    display: inline-block;
    min-width: inherit;
    vertical-align: top;
    white-space: normal;

    .tile-inner {
        width: 100%;
        height: 100%;
        margin: 0;
        transition: width .3s,height .3s, margin .3s;
        position: relative;
        padding: 0 0 14px 0;

        .content {
            width: 100%;
            height: calc(100% - 9px);
            background-color: #575757;
            position: relative;
            float: left;
            cursor: default;
            color: $primary-color !important;

            &:before {
                content: '';
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                position: absolute;
                background-color: #7DB5E1;
            }

            &.isTopic:before {
                background-color: #F85769;
            }

            .label {
                position: absolute;
                top: 0;
                left: 0;
                padding: 2px 10px 0 10px;
                font-size: 10px;
                line-height: 16px;
                text-align: center;
                min-width: inherit;
                color: $primary-color;
                height: 18px;
                display: inline-flex;
                align-items: center;

                &.fresh {
                    background-color: #2E61B2;
                }

                &.hot {
                    background-color: #D20423;
                }
            }

            .bb-icons {
                height: calc(100% - 20px);
                overflow: hidden;

                .bb-icon {
                    &:not(:last-child) {
                        padding: 0 0 6px 0;
                    }

                    &:last-child {
                        padding: 0;
                    }


                    &.separator + div {
                        padding: 6px 0 6px 0;
                    }
                }

                div {
                    display: flex;

                    p {
                        opacity: 0;
                        max-height: 0;
                        margin: 0;
                        width: auto;
                        flex-shrink: 0;

                        &:last-child {
                            margin: 0 !important;
                        }

                        &:not(:last-child) {
                            margin: 0 5px 0 0 !important;
                        }
                    }
                }

                .bb-icon.active svg .st0 {
                    fill: #fff;
                    stroke: #fff;
                }
            }

            .tile-content {
                position: absolute;
                top: 30px;
                left: 25px;
                width: calc(100% - 50px);
                height: 120px;
                max-height: calc(100% - 40px);
                display: flex;
                flex-direction: column;

                .t-content-top {
                    display: flex;
                    margin: 0 10px 0 0;

                    .t-content-left {
                        margin: 0 15px 0 0;
                        display: flex;
                        flex-direction: column;

                        .t-image {
                            width: 78px;
                            height: 68px;
                            border: 1px solid #fff;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            img {
                                width: calc(100% - 20px);
                            }
                        }

                        .t-category {
                            width: 80px;
                            height: 14px;
                            padding: 1px 0 0 0;
                            background-color: #000;
                            font-size: 10px;
                            line-height: 15px;
                            text-align: center;
                            color: $themeRed;
                            font-family: $font-bold;
                        }
                    }

                    .t-content-right {
                        line-height: 16px;
                        font-size: 14px;

                        > div:first-child {
                            margin: 0 0 5px 0;
                            width: 100%;
                            font-family: $font-regular;
                            color: #BE3449;
                            font-size: 12px;
                        }

                        > div:not(:first-child) {
                            font-family: $font-bold;
                            font-weight: 700;
                            font-size: 12px;
                        }
                    }
                }

                .t-content-bottom {
                    display: flex;
                    margin: 5px 5px 0 0;

                    .t-embed {
                        font-size: 10px;
                        font-family: $font-light;
                        cursor: pointer;
                        width: 80px;
                        margin: 0 15px 0 0;
                        display: flex;
                        align-items: center;

                        svg {
                            width: 13px;
                            height: 4px;
                            margin: 0 5px 0 0;
                            transition: transform .3s;

                            .st0 {
                                stroke: #fff;
                            }
                        }
                    }

                    .t-media {
                        display: flex;
                        align-items: center;

                        svg {
                            height: 12px;
                            width: 18px;
                        }
                    }
                }
            }

            &.isBite .tile-content {
                .t-content-top .t-category {
                    color: $themeBlue;
                }

                .t-content-right > div:first-child {
                    color: #4679A1;
                }
            }
        }

        .progress {
            height: 4px;
            margin: 3px 0 0 0;
            float: left;
        }
    }
}

.content-main > div:not(.context-open) div:not(.slide-inner):not(.adaptivecards-container-inner) > .tile,
.content-main > .context-open div:not(.slide-inner):not(.adaptivecards-container-inner) > .tile.fixed {
    .tile-inner:hover, &.fixed .tile-inner {
        z-index: 1;
        width: 130%;
        height: 130%;
        margin: -9% 0 0 -14%;

        .content {
            background-color: #fff !important;
            box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.25);

            .tile-content {
                width: calc(100% - 90px);
                bottom: 25px;
                margin: auto 0;
            }

            .bb-icons {
                width: auto;


                .bb-icon {
                    width: 100%;
                    display: flex;
                    align-items: center;

                    svg {
                        height: auto;
                        width: 16px;
                        margin: 0 0 2px 0;
                    }

                    p {
                        opacity: 1;
                        max-height: 40px;
                        margin: 3px 0 0 0;
                    }

                    &:not(:last-child) {
                        padding: 0 0 5px 0;
                    }

                    &.separator {
                        padding: 0 0 10px 0;

                        &:after {
                            width: 16px;
                            margin: 0 auto 0 auto;
                        }

                        + div {
                            padding: 5px 0 5px 0;
                        }
                    }
                }
            }
        }

        .progress {
            display: none;
        }
    }
}

div:not(.slide-inner):not(.adaptivecards-container-inner) > .tile.fixed {
    .tile-inner {
        z-index: 2;

        .content .tile-content .t-embed svg {
            transform: scaleY(-1);
        }

        .embed {
            max-height: 750px;
        }
    }
}
