.model-carousel {
    width: 100%;
    position: relative;
    z-index: 6;

    .content-wrapper {
        width: 100%;
        max-width: inherit;
    }
    .logoBanner{
        background-image: url(../../images/theme/night/holos-logo-dark-mode.svg);
        width: 92px;
        height: 30px;
        position: fixed;
        top: 10px;
        left: 17px;
    }


    .slider {
        width: 100%;
        /*height: 500px;*/
        /*min-height: 231px;*/
        max-height: 612px;
        padding: 0px 0 50px;
        margin: auto;
        margin-top: 15px;
        position: relative;
        transition: max-height 1s;
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        /*> div {
            z-index: -1;
            width: 0;
            height: 0;
            opacity: 0;
        }*/
        .model-container {
            list-style: none;
            position: relative;
            width: 100%;
            margin: 0;
            padding: 0;
            overflow: hidden;
            height: 482px;
            max-height: 0;
            transition: max-height 1s;

            &.zooming {
                z-index: 4;
            }

            > div {
                /*overflow: hidden;*/
                position: absolute;
                z-index: 0;
                left: 377px;
                top: 146px;
                width: 0;
                height: 0;
                margin: 0;
                padding: 0;
                transition: left $slideSpeed, height $slideSpeed, width $slideSpeed, top $slideSpeed;

                &.active {
                    width: 490px;
                    height: 482px;
                    left: calc(50% - 245px);
                    top: 0;
                    z-index: 3;

                    &:first-child {
                        width: 800px;
                        left: calc(50% - 400px);
                    }

                    .model4-symbols {
                        opacity: 1;
                    }

                    .square {
                        height: calc(100% - 20px);
                    }

                    &:nth-child(3) {
                        width: 416px;
                        height: 416px;
                        left: calc(50% - 208px);
                        top: 40px;
                    }
                }


                &:not(.active) {
                    width: 236px;
                    height: 236px;
                    top: 120px;
                    z-index: 1;
                    cursor: pointer;
                }

                &.prev2 {
                    left: calc(50% - 692px);
                    z-index: 0;
                }

                &.prev {
                    left: calc(50% - 505px);
                }

                &.next {
                    left: calc(50% + 265px);
                }

                &.next2 {
                    left: calc(50% + 452px);
                    z-index: 0;
                }

                svg:not(.model) {
                    position: absolute;
                    bottom: 0;
                    top: 0;
                    right: 0;
                    left: 0;
                    visibility: visible;
                    opacity: 1;
                    // transition: visibility 0s, opacity 0.5s linear;
                }

                &.active {
                    .slide-container .model {
                        position: absolute;
                        width: calc(100% - 56px);
                        height: calc(100% - 48px);
                        left: 23px;
                        top: 23px;
                    }
                }

                .slide-container {
                    position: relative;
                    width: 100%;
                    height: 100%;

                    .icon-zoom {
                        width: 35px;
                        height: auto;
                        left: inherit;
                        right: -40px;
                        bottom: inherit;
                        top: 75px;
                        cursor: pointer;
                        z-index: 1;
                        opacity: 0;
                        transition: opacity .3s;
                    }

                    &.rectangle {
                        .icon-zoom {
                            right: -50px;
                            top: 25px;
                        }
                    }

                    &.square {
                        .icon-zoom {
                            right: -50px;
                            top: 5px;
                        }
                    }

                    .close-zoom {
                        width: 100%;
                        max-width: 1400px;
                        height: 30px;
                        position: absolute;
                        right: 0;
                        left: 0;
                        top: 30px;
                        display: none;
                        width: calc(100% - 110px);
                        padding: 0 55px;
                        margin: 0 auto;
                        justify-content: flex-end;
                        z-index: 1;

                        &.pos-bottom {
                            position: relative;
                            justify-content: center;
                            top: inherit;
                        }

                        .scroll-indication {
                            position: relative;
                            top: inherit;
                            bottom: inherit;
                            cursor: pointer;

                            .scroll-indication-arrow svg {
                                position: relative;
                            }
                        }

                        .fullscreenClose {
                            position: relative;
                            width: 30px;
                            height: 30px;
                            cursor: pointer;
                        }
                    }

                    .model {
                        width: calc(100% - 29px);
                        height: calc(100% - 29px);
                        position: absolute;
                        left: 15px;
                        top: 14px;
                        z-index: 1;

                        .st4 {
                            font-family: $font-regular;
                        }

                        .st15 {
                            font-family: $font-bold;
                        }
                    }

                    svg {
                        .level {
                            .st1, .st2 {
                                fill: transparent;
                                transition: fill .3s;
                            }

                            &.l1 {
                                &:hover .st2 {
                                    fill: #D20523;
                                    transition: fill .3s;
                                }

                                &:not(.active) {
                                    cursor: pointer;
                                }
                            }

                            &.rect:not(.l1) {
                                &:not(.active) {
                                    cursor: pointer;

                                    &:hover .st1 {
                                        fill: #D20523;
                                        transition: fill .3s;
                                    }
                                }
                            }

                            &.active {
                                .st1, .st2 {
                                    fill: #D20523;
                                }
                            }
                        }

                        .hidden {
                            display: none;
                        }
                    }

                    &.magnify {
                        position: fixed;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        top: 0;
                        height: 100%;
                        background-color: #242424;

                        > svg:not(.model) {
                            position: relative;
                            margin: 20px 20px 20px 25px;
                            max-height: calc(100% - 100px);
                            max-width: calc(100% - 35px);
                        }

                        > svg.model {
                            height: calc(100% - 175px);
                            width: auto;
                            left: 0;
                            top: 57px;
                            right: 0;
                            margin: auto;
                            max-width: calc(100% - 140px);
                        }

                        .icon-zoom {
                            display: none;
                        }

                        .close-zoom {
                            display: flex;
                        }

                        .copyright-malik{
                            bottom: 50px !important;
                        }

                        &.rectangle {
                            padding: 80px 0 0 0;

                            .model4-container {
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                max-height: calc(100% - 300px);
                                left: 0;
                                right: 0;
                                top: 105px;
                                margin: 0%;
                                display: flex;
                                justify-content: center;

                                .model4-container-inner {
                                    position: relative;
                                    transform: translateX(-30px);
                                    height: 100%;
                                    display: inline-block;
                                    max-height: 800px;


                                    svg:first-child {
                                        position: relative;
                                        width: auto;
                                        height: 100%;
                                        top: inherit;
                                        bottom: inherit;
                                        left: inherit;
                                        right: inherit;
                                    }


                                    svg:last-child {
                                        position: absolute;
                                        width: auto;
                                        height: calc(100% - 50px);
                                        top: 20px;
                                    }
                                }
                            }

                            .close-zoom.pos-bottom {
                                top: calc(100% - 82px);
                            }
                        }
                    }

                    .copyright-malik {
                        font-size: 14px;
                        line-height: 16px;
                        position: absolute;
                        bottom: 0;
                        right: 5px;
                        opacity: 0;
                        transition: opacity 1s;
                    }

                    &.square .copyright-malik {
                        bottom: -23px;
                    }
                }

                &:not(.active) {
                    .modelActive, .model-active {
                        visibility: hidden;
                        opacity: 0;
                    }
                }

                &.active {


                    .model-inactive {
                        visibility: hidden;
                        opacity: 0;
                    }

                    .copyright-malik {
                        opacity: 1;
                    }
                }
            }

            &:hover > .active .icon-zoom {
                opacity: 1;
            }
        }
        /* scroll-indication */
        .scroll-indication {
            position: absolute;
            bottom: 130px;
            margin: 0 auto;
            background-color: rgba(0,0,0,.7);
            color: #fff;
            display: flex;
            flex-direction: column;
            align-self: center;
            padding: 10px 20px;
            z-index: -1;
            opacity: 0;
            transition: opacity .3s;
            font-family: $font-bold;
            font-size: 14px;
            line-height: 16px;

            &.show {
                opacity: 1;
                z-index: 2;
            }

            .scroll-indication-text {
                width: auto;
            }

            .scroll-indication-arrow {
                width: 20px;
                align-self: center;

                svg {
                    -webkit-animation: arrowBounce 1.5s infinite;
                    -moz-animation: arrowBounce 1.5s infinite;
                    -o-animation: arrowBounce 1.5s infinite;
                    animation: arrowBounce 1.5s infinite;


                    .st0 {
                        stroke: #fff;
                    }
                }
            }
        }
        /* slide nav */
        .slider-navigation {
            width: 100%;
            height: auto;
            display: flex;
            margin: 0 0 25px 0;
            transition: margin .3s;

            .slider-navigation-container {
                margin: auto;
                display: flex;
                /*flex: 0 0 auto;*/
                .slide-nav {
                    width: 30px;
                    height: 50px;
                    position: relative;
                    font-size: 14px;
                    line-height: 20px;
                    align-self: center;
                    cursor: pointer;

                    &.center {
                        width: 60px;
                        height: 80px;
                        text-transform: uppercase;

                        span {
                            width: 250px;
                            font-family: $font-bold;
                            font-weight: 700;
                        }
                    }

                    span {
                        position: absolute;
                        width: 157px;
                        color: #fff;
                        text-align: center;
                        top: calc(100%);
                        left: 50%;
                        transform: translateX(-50%);
                        /*white-space: pre;
                        word-break: initial;*/
                    }
                }

                .line {
                    width: 200px;
                    height: 1px;
                    background-color: #fff;
                    margin: 0 10px;
                    align-self: center;

                    &:nth-child(2), &:nth-child(8) {
                        width: 135px;
                    }

                    &:nth-child(4), &:nth-child(6) {
                        width: 320px
                    }
                }
            }
        }

        &.loading {
            height: 121px;
        }
    }

    #modelsSizeToggle:checked + .slider {
        .model-container {
            max-height: 600px;
        }

        .slider-navigation {
            margin-top: 0px;
        }
    }

    #modelsSizeToggle:checked + div + .view-toggle svg {
        transform: scaleY(-1);
    }

    .view-toggle {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        line-height: 14px;

        label {
            margin: 0 0 0 10px;
            border-left: 1px solid #fff;
            height: 16px;
            display: flex;
            align-items: center;


            svg {
                margin: 0 0 0 10px;
                height: 6px;
                width: auto;
                cursor: pointer;
                transition: transform .3s;

                .st0 {
                    stroke: #fff;
                }
            }
        }
    }

    .model-sub-navigation {
        width: 100%;
        /*flex-direction: column;*/
        margin: 50px 0 0 0;
        font-size: 14px;
        line-height: 17px;
        position: relative;
        z-index: 1;

        .msn-menu {
            width: 966px;
            margin: 0 auto 20px;
            display: flex;
            min-height: 16px;

            .msn-menu-nav {
                width: 100%;
                font-family: $font-bold;
                font-weight: 700;
                padding: 0 0 0 15px;

                &:nth-child(2) {
                    color: $themeRed;
                }

                &:last-child {
                    color: $themeBlue;
                }
            }
        }

        .msn-outer {
            width: 966px;
            max-width: 100%;
            margin: 0 auto 0 auto;
            position: relative;
            min-height: 177px;
            transition: min-height .3s;


            .msn-outer-container {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                padding: 0 0 16px;
                border-bottom: 1px solid #fff;
                z-index: 3;
                background-color: rgba(40,40,40,.6);

                .loader-container {
                    overflow: hidden;
                }
            }

            .msn-inner {
                position: relative;
                width: 100%;
                max-height: 160px;
                min-height: 160px;
                overflow: hidden;
                display: flex;
                transition: max-height .3s,min-height .3s;

                .msn-section {
                    /*width: calc((100% - (25px * 4 + 16px * 2 + 2px)) / 3);*/
                    width: 100%;
                    padding: 0 0 0 15px;
                    margin: 16px 8px 0 0;
                    height: auto;
                    display: flex;
                    flex-direction: column;

                    &:not(:nth-child(2)) {
                        border-left: 1px solid #fff;
                    }

                    &:nth-child(4) {
                        margin: 16px 5px 0 0;
                        padding: 0 0 0 15px;
                    }

                    .msn-alphabet {
                        width: 100%;
                        margin: 0 0 15px 0;
                        font-size: 10px;
                        line-height: 12px;

                        span {
                            margin: 0 3px 0 0;
                            cursor: pointer;
                            transition: color .3s;

                            &.active, &:hover {
                                font-family: $font-bold;
                                font-weight: 700;
                                color: #2d64af;
                            }
                        }
                    }

                    .msn-section-inner {
                        display:flex;
                        flex-direction: column;
                        overflow-y: auto;
                        width: calc(100% - 20px);
                        height: 100%;
                        padding: 0 20px 0 0;

                        .hidden {
                            display: none;
                        }

                        .disabled {
                            color: gray;
                            cursor: not-allowed;
                        }

                        > p, > a {
                            margin: 0 0 10px 0;
                        }

                        > p, > a {
                            cursor: pointer;
                            transition: color .3s;
                            font-family: $font-regular;

                            &.active, &:hover {
                                color: $secondary-color;
                            }
                        }
                        .grey {
                            color: lightgrey;
                            cursor: not-allowed;
                        }
                    }
                }
            }

            .arrow {
                bottom: 0;
                position: absolute;
                width: 100%;
                height: 6px;
                left: 0;
                right: 0;
                cursor: pointer;
                padding: 5px 0;
                display: flex;

                &.hide {
                    display: none;
                }

                &.down {
                    bottom: 8px;
                }

                &.up {
                    top: 0;
                }

                &.rotate180 {
                    transform: rotate(180deg);
                }

                svg {
                    height: 6px;
                    margin: 0 auto;

                    .st0 {
                        stroke: #969696;
                    }
                }
            }
        }
    }
}

@-webkit-keyframes arrowBounce {
    0% {
        transform: translateY(5px);
        -webkit-transform: translateY(5px);
        -moz-transform: translateY(5px);
        -o-transform: translateY(5px);
    }

    50% {
        transform: translateY(-5px);
        -webkit-transform: translateY(-5px);
        -moz-transform: translateY(-5px);
        -o-transform: translateY(-5px);
    }

    100% {
        transform: translateY(5px);
        -webkit-transform: translateY(5px);
        -moz-transform: translateY(5px);
        -o-transform: translateY(5px);
    }
}

@keyframes arrowBounce {
    0% {
        transform: translateY(5px);
        -webkit-transform: translateY(5px);
        -moz-transform: translateY(5px);
        -o-transform: translateY(5px);
    }

    50% {
        transform: translateY(-5px);
        -webkit-transform: translateY(-5px);
        -moz-transform: translateY(-5px);
        -o-transform: translateY(-5px);
    }

    100% {
        transform: translateY(5px);
        -webkit-transform: translateY(5px);
        -moz-transform: translateY(5px);
        -o-transform: translateY(5px);
    }
}

.modelActive {
    .st0 {
        fill: #FFFFFF;
    }

    .st1 {
        fill: none;
        stroke: #FFFFFF;
        stroke-width: 0.125;
        stroke-miterlimit: 10;
    }

    .st2 {
        fill: none;
        stroke: #FFFFFF;
        stroke-width: 0.125;
        stroke-miterlimit: 10;
    }

    .level {
        &:hover, &.active {
            /*cursor: pointer;*/
            .st2 {
                fill: #D20523;
            }
        }
    }
}


.model-container > div.active, .e-model {


    .model3 {
        .lv1 {
            .model-option {
                &.hover, &.active {
                    .st0 {
                        opacity: .25 !important;
                    }

                    .st10, .st13 {
                        opacity: 0.4 !important;
                    }

                    .st1 {
                        opacity: .4 !important;
                    }

                    .st2, .st7 {
                        opacity: 1 !important;
                    }
                }
            }

            .model-circle {
                &.hover, &.active {
                    .st9, .st10, .st11, .st12 {
                        opacity: 1 !important;
                    }
                }
            }
        }

        .lv2 {
            .model-option {
                &.hover, &.active {
                    .st7 {
                        opacity: 1;
                    }

                    .st9 {
                        opacity: 0.8;
                    }
                }
            }
        }
    }

    .model2 {
        .lv1 {
            .model-option {
                &.hover, &.active {
                    .st0 {
                        opacity: 1;
                    }

                    .st2 {
                        opacity: 1;
                    }

                    .st3 {
                        opacity: 1;
                    }

                    .st8, .st9, .st10, .st11 {
                        opacity: 1;
                    }
                }
            }
        }
    }



    .model4 {
        .lv1, .lv2 {
            .model-option {
                &.hover, &.active {

                    &.st2, &.st3, &.st5, &.st6, &.st16 {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.e-model .copyright-malik {
    display: none;
}

svg.switch {
    .lv1 {
        display: none;
    }

    .lv2 {
        display: block;
    }
}

.model-container > div.active {
    .model3 {
        .lv1 {
            * {
                cursor: pointer;
            }
        }
    }
}

.e-model .model2 *, .e-model .model3 *, .e-model .model4 * {
    cursor: default !important
}

.model1:not(.model1-en) {
    /*.st0 {
        display: none;
    }

    .st1 {
        display: inline;
        fill: #FFFFFF;
    }

    .st2 {
        opacity: 0.9;
        fill: #B3CCE6;
    }

    .st3 {
        opacity: 0.3;
        fill: #425C75;
    }

    .st4 {
        opacity: 0.5;
        fill: #425C75;
    }

    .st5 {
        fill: #E6FFFF;
    }

    .st6 {
        fill: none;
    }

    .st7 {
        opacity: 0.7;
        fill: #263F59;
    }

    .st8 {
        font-family: 'AGBuchBQ-Medium';
    }

    .st9 {
        font-size: 9.12px;
    }

    .st10 {
        font-size: 9.1201px;
    }

    .st11 {
        fill: #9BB5CE;
    }

    .st12 {
        opacity: 0.5;
        fill: #112B44;
    }

    .st13 {
        fill: none;
        stroke: #E6FFFF;
        stroke-width: 0.35;
        stroke-miterlimit: 10;
    }

    .st14 {
        fill: none;
        stroke: #E6FFFF;
        stroke-width: 2;
        stroke-miterlimit: 10;
    }

    .st15 {
        opacity: 0.9;
        fill: #D9F2FF;
    }

    .st16 {
        opacity: 0.6;
        fill: #9BB5CE;
    }

    .st17 {
        opacity: 0.4;
        fill: #2F4962;
    }

    .st18 {
        opacity: 0.4;
        fill: #425C75;
    }

    .st19 {
        opacity: 0.4;
        fill: #112B44;
    }

    .st20 {
        fill: #5E7891;
    }

    .st21 {
        fill: #7C96AF;
    }

    .st22 {
        fill: #000019;
    }

    .st23 {
        fill: none;
        stroke: #E6FFFF;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-miterlimit: 10;
    }

    .st24 {
        opacity: 0.9;
        fill: #5E7891;
    }

    .st25 {
        fill-rule: evenodd;
        clip-rule: evenodd;
        fill: url(#SVGID_1_);
    }

    .st26 {
        opacity: 0.6;
        fill: none;
        stroke: #112B44;
        stroke-width: 4;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-miterlimit: 10;
    }

    .st27 {
        opacity: 0.6;
        fill: none;
        stroke: #263F59;
        stroke-width: 4;
        stroke-miterlimit: 10;
    }

    .st28 {
        opacity: 0.6;
        fill: #263F59;
    }

    .st29 {
        opacity: 0.6;
        fill: none;
        stroke: #2F4962;
        stroke-width: 4;
        stroke-miterlimit: 10;
    }

    .st30 {
        opacity: 0.6;
        fill: #2F4962;
    }

    .st31 {
        opacity: 0.5;
    }

    .st32 {
        opacity: 0.98;
        fill: none;
        stroke: #E6FFFF;
        stroke-width: 2;
        stroke-miterlimit: 10;
    }

    .st33 {
        opacity: 0.98;
        fill: none;
        stroke: #E6FFFF;
        stroke-width: 0.35;
        stroke-miterlimit: 10;
    }

    .st34 {
        opacity: 0.5;
        fill: none;
        stroke: #E6FFFF;
        stroke-width: 2;
        stroke-miterlimit: 10;
    }

    .st35 {
        opacity: 0.9;
        fill: #99B2CC;
    }

    .st36 {
        opacity: 0.2;
        fill: #2F4962;
    }

    .st37 {
        opacity: 0.3;
        fill: #112B44;
    }

    .st38 {
        opacity: 0.5;
        fill: #2F4962;
    }

    .st39 {
        opacity: 0.5;
        fill: #9BB5CE;
    }

    .st40 {
        opacity: 0.5;
        fill: #263F59;
    }

    .st41 {
        fill: none;
        stroke: #E6FFFF;
        stroke-width: 0.5;
        stroke-miterlimit: 10;
    }*/
    .cls-1 {
        fill: #b3cce6;
    }

    .cls-1, .cls-11, .cls-20, .cls-31 {
        opacity: 0.9;
    }

    .cls-1, .cls-11, .cls-12, .cls-13, .cls-14, .cls-15, .cls-2, .cls-20, .cls-22, .cls-23,
    .cls-24, .cls-25, .cls-26, .cls-28, .cls-29, .cls-3, .cls-30, .cls-31, .cls-32, .cls-33, .cls-34, .cls-35, .cls-36, .cls-6, .cls-8 {
        isolation: isolate;
    }

    .cls-14,
    .cls-2, .cls-3 {
        fill: #425c75;
    }

    .cls-2, .cls-33 {
        opacity: 0.3;
    }

    .cls-27, .cls-3, .cls-30, .cls-34, .cls-35, .cls-36, .cls-8 {
        opacity: 0.5;
    }

    .cls-4 {
        fill: #e6ffff;
    }

    .cls-10,
    .cls-19, .cls-22, .cls-23, .cls-25, .cls-28, .cls-29, .cls-30, .cls-37, .cls-5, .cls-9 {
        fill: none;
    }

    .cls-24, .cls-36, .cls-6 {
        fill: #263f59;
    }

    .cls-6 {
        opacity: 0.7;
    }

    .cls-12,
    .cls-35, .cls-7 {
        fill: #9bb5ce;
    }

    .cls-15, .cls-33, .cls-8 {
        fill: #112b44;
    }

    .cls-10, .cls-19, .cls-28, .cls-29, .cls-30, .cls-37, .cls-9 {
        stroke: #e6ffff;
    }

    .cls-10, .cls-23,
    .cls-25, .cls-28, .cls-29, .cls-30, .cls-37, .cls-9 {
        stroke-miterlimit: 10;
    }

    .cls-29, .cls-9 {
        stroke-width: 0.35px;
    }

    .cls-10, .cls-19, .cls-28, .cls-30 {
        stroke-width: 2px;
    }

    .cls-11 {
        fill: #d9f2ff;
    }

    .cls-12, .cls-22, .cls-23, .cls-24, .cls-25, .cls-26 {
        opacity: 0.6;
    }

    .cls-13, .cls-26, .cls-32, .cls-34 {
        fill: #2f4962;
    }

    .cls-13, .cls-14, .cls-15 {
        opacity: 0.4;
    }

    .cls-16, .cls-20 {
        fill: #5e7891;
    }

    .cls-17 {
        fill: #7c96af;
    }

    .cls-18 {
        fill: #000019;
    }

    .cls-19, .cls-22 {
        stroke-linecap: round;
        stroke-linejoin: round;
    }

    .cls-21 {
        fill-rule: evenodd;
        fill: url(#Gradiente_sem_nome);
    }

    .cls-22 {
        stroke: #112b44;
    }

    .cls-22, .cls-23, .cls-25 {
        stroke-width: 4px;
    }

    .cls-23 {
        stroke: #263f59;
    }

    .cls-25 {
        stroke: #2f4962;
    }

    .cls-28, .cls-29 {
        opacity: 0.98;
    }

    .cls-31 {
        fill: #99b2cc;
    }

    .cls-32 {
        opacity: 0.2;
    }

    .cls-37 {
        stroke-width: 0.5px;
    }
}

.model1-en {

    .cls-1, .cls-7 {
        fill: none;
    }

    .cls-1 {
        clip-rule: evenodd;
    }

    .cls-15, .cls-16, .cls-2, .cls-21, .cls-24, .cls-4, .cls-6, .cls-8 {
        isolation: isolate;
    }

    .cls-2 {
        opacity: 0.9;
    }

    .cls-3 {
        fill: #b3cce6;
    }

    .cls-4 {
        opacity: 0.3;
    }

    .cls-5 {
        fill: #425c75;
    }

    .cls-22, .cls-6 {
        opacity: 0.5;
    }

    .cls-8 {
        opacity: 0.7;
    }

    .cls-9 {
        fill: #263f59;
    }

    .cls-10 {
        fill: #9bb5ce;
    }

    .cls-11 {
        fill: #112b44;
    }

    .cls-12 {
        fill: #e6ffff;
    }

    .cls-13 {
        fill: #fff;
    }

    .cls-14 {
        fill: #d9f2ff;
    }

    .cls-15 {
        opacity: 0.6;
    }

    .cls-16 {
        opacity: 0.4;
    }

    .cls-17 {
        fill: #2f4962;
    }

    .cls-18 {
        fill: #5e7891;
    }

    .cls-19 {
        fill: #7c96af;
    }

    .cls-20 {
        clip-path: url(#clip-path);
    }

    .cls-21 {
        opacity: 0.98;
    }

    .cls-23 {
        fill: #99b2cc;
    }

    .cls-24 {
        opacity: 0.2;
    }
}

.model2 {
    .lv1 {
        .st0 {
            opacity: 0.5;
            fill: #006E46;
            cursor: pointer;
            transition: opacity .3s;
        }

        .st1 {
            fill: #FFFFFF;
        }

        .st2 {
            opacity: 0.5;
            fill: #2850AA;
            cursor: pointer;
            transition: opacity .3s;
        }

        .st3 {
            opacity: 0.8;
            fill: #2850AA;
            cursor: pointer;
            transition: opacity .3s;
        }

        .st4 {
            opacity: 0.25;
            fill: #2D64AE;
        }

        .st5 {
            opacity: 0.25;
            fill: #C8001E;
        }

        .st6 {
            opacity: 0.3;
            fill: #006E46;
        }

        .st7 {
            opacity: 0.5;
            fill: none;
            stroke: #FFFFFF;
            stroke-width: 0.35;
            stroke-miterlimit: 10;
        }

        .st8 {
            fill: #006E46;
            transition: opacity .3s;
        }

        .st9 {
            fill: #FAAA32;
            transition: opacity .3s;
        }

        .st10 {
            fill: #2850AA;
            transition: opacity .3s;
        }

        .st11 {
            fill: #C8001E;
            transition: opacity .3s;
        }

        .st8, .st9, .st10, .st11 {
            opacity: .6;
            cursor: pointer;
        }

        .st12 {
            fill: none;
            stroke: #FFFFFF;
            stroke-width: 0.5;
            stroke-miterlimit: 10;
        }
    }

    .lv2 {
        display: none;


        .st0 {
            display: none;
        }

        .st1 {
            display: inline;
        }

        .st2 {
            opacity: 0.8;
        }

        .st3 {
            fill: #2850AA;
        }

        .st4 {
            opacity: 0.6;
            fill: #2850AA;
            enable-background: new;
        }

        .st5 {
            opacity: 0.4;
            fill: #2850AA;
            enable-background: new;
        }

        .st6 {
            opacity: 0.2;
            fill: #2850AA;
            enable-background: new;
        }

        .st7 {
            fill: #006E46;
        }

        .st8 {
            fill: #FFFFFF;
        }

        .st9 {
            fill: none;
            stroke: #FFFFFF;
            stroke-width: 0.35;
            stroke-miterlimit: 10;
        }
    }
}

.model3 {
    .lv1 {

        .st0 {
            opacity: 0.25;
            fill: #2D64AE;
            transition: opacity .3s;
        }

        .st1 {
            opacity: 0.2;
            fill: #C8001E;
            transition: opacity .3s;
        }

        .st2 {
            opacity: 0.5;
            fill: #C8001E;
            transition: opacity .3s;
        }

        .st3 {
            opacity: 0.4;
            fill-rule: evenodd;
            clip-rule: evenodd;
            fill: #FFFFFF;
            stroke: #FFFFFF;
            stroke-miterlimit: 10;
        }

        .st4 {
            fill: #FFFFFF;
        }

        .st5 {
            fill: transparent;
        }

        .st6 {
            opacity: 0.4;
            fill: #C8001E;
        }

        .st7 {
            opacity: 0.7;
            fill: #C8001E;
            transition: opacity .3s;
        }

        .st8 {
            font-family: 'AGBuchBQ-Medium';
        }

        .st9 {
            font-size: 19px;
            transition: opacity .3s;
        }

        .st10 {
            opacity: 0.4;
            fill: #FAAA32;
            transition: opacity .3s;
        }

        .st11 {
            opacity: 0.7;
            fill: #FAAA32;
            transition: opacity .3s;
        }

        .st12 {
            opacity: 0.6;
            fill: #2850AA;
            transition: opacity .3s;
        }

        .st13 {
            opacity: 0.98;
            fill: none;
            stroke: #FFFFFF;
            stroke-width: 0.35;
            stroke-miterlimit: 10;
            transition: opacity .3s;
        }

        .st14 {
            opacity: 0.98;
            fill: transparent;
        }

        .stroke-double {
            stroke-width: 2;
            stroke: #fff;
        }

        .st15 {
            fill: transparent;
            opacity: 0;
            fill: #FFFFFF;
        }

        > * {
            cursor: pointer;
        }

        .model-circle {
            .st9 {
                opacity: .2;
            }
        }
    }

    .lv2 {
        display: none;

        .st0 {
            opacity: 0.4;
            fill: #2D64AE;
        }

        .st1 {
            opacity: 0.3;
            fill: #C8001E;
        }

        .st2 {
            opacity: 0.25;
            fill: #2D64AE;
        }

        .st3 {
            opacity: 0.5;
            fill: #C8001E;
        }

        .st4 {
            opacity: 0.1;
            fill: #2D64AE;
        }

        .st5 {
            opacity: 0.7;
            fill: #C8001E;
        }

        .st6 {
            fill: #C8001E;
        }

        .st7 {
            fill: #FAAA32;
            opacity: .7;
            cursor: pointer;
            transition: opacity .3s;
        }

        .st8 {
            fill: #FFFFFF;
        }

        .st9 {
            opacity: 0.6;
            fill: #FAAA32;
            cursor: pointer;
            transition: opacity .3s;
        }

        .st10 {
            opacity: 0.6;
            fill: #2850AA;
        }

        .st11 {
            fill: none;
            stroke: #FFFFFF;
            stroke-width: 0.35;
            stroke-miterlimit: 10;
        }
    }
}

.model4 {
    .st-transparent {
        fill: transparent;
    }

    .lv1, .lv2 {
        cursor: pointer;

        .st0 {
            display: none;
        }

        .st1 {
            display: inline;
        }

        .st2 {
            opacity: 0.8;
            fill: #FAAA32;
        }

        .st3 {
            opacity: 0.8;
            fill: #006E46;
        }

        .st4 {
            opacity: 0.25;
            fill: #2D64AE;
        }

        .st5 {
            opacity: 0.8;
            fill: #C8001E;
        }

        .st6 {
            opacity: 0.8;
            fill: #2850AA;
        }

        .st7 {
            opacity: 0.2;
            fill: #7896B4;
        }

        .st8 {
            opacity: 0.25;
            fill: #7896B4;
        }

        .st9 {
            opacity: 0.35;
            fill: #7896B4;
        }

        .st10 {
            opacity: 0.4;
            fill: #7896B4;
        }

        .st11 {
            opacity: 0.45;
            fill: #7896B4;
        }

        .st12 {
            opacity: 0.5;
            fill: #7896B4;
        }

        .st13 {
            opacity: 0.55;
            fill: #7896B4;
        }

        .st14 {
            opacity: 0.6;
            fill: #7896B4;
        }

        .st15 {
            fill: none;
            stroke: #FFFFFF;
            stroke-width: 0.35;
            stroke-miterlimit: 10;
        }

        .st16 {
            opacity: 0.7;
            fill: #96A5B4;
        }

        .st17 {
            opacity: 0.5;
        }

        .st18 {
            fill: #FFFFFF;
        }

        .st19 {
            opacity: 0.5;
            fill: none;
            stroke: #FFFFFF;
            stroke-width: 2;
            stroke-miterlimit: 10;
        }

        .st20 {
            fill: none;
            stroke: #FFFFFF;
            stroke-width: 2;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-miterlimit: 10;
        }
    }

    .lv2 {
        display: none;
        cursor: pointer;
        /*.st0 {
            display: none;
        }

        .st1 {
            display: inline;
        }

        .st2 {
            opacity: 0.8;
            fill: #FAAA32;
        }

        .st3 {
            opacity: 0.8;
            fill: #006E46;
        }

        .st4 {
            opacity: 0.25;
            fill: #2D64AE;
        }

        .st5 {
            opacity: 0.8;
            fill: #C8001E;
        }

        .st6 {
            opacity: 0.8;
            fill: #003278;
        }

        .st7 {
            opacity: 0.2;
            fill: #787878;
        }

        .st8 {
            opacity: 0.25;
            fill: #787878;
        }

        .st9 {
            opacity: 0.35;
            fill: #787878;
        }

        .st10 {
            opacity: 0.4;
            fill: #787878;
        }

        .st11 {
            opacity: 0.45;
            fill: #787878;
        }

        .st12 {
            opacity: 0.5;
            fill: #787878;
        }

        .st13 {
            opacity: 0.55;
            fill: #787878;
        }

        .st14 {
            opacity: 0.6;
            fill: #787878;
        }

        .st15 {
            fill: none;
            stroke: #FFFFFF;
            stroke-width: 0.35;
            stroke-miterlimit: 10;
        }

        .st16 {
            opacity: 0.9;
            fill: #969696;
        }

        .st17 {
            opacity: 0.5;
        }

        .st18 {
            fill: #FFFFFF;
        }

        .st19 {
            opacity: 0.5;
            fill: none;
            stroke: #FFFFFF;
            stroke-width: 2;
            stroke-miterlimit: 10;
        }

        .st20 {
            fill: none;
            stroke: #FFFFFF;
            stroke-width: 2;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-miterlimit: 10;
        }*/
    }
}

.model5 {
    .st0 {
        opacity: 0.8;
        fill: #788CA0;
    }

    .st1 {
        opacity: 0.8784;
        fill: none;
        stroke: #2850AA;
        stroke-width: 4;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-miterlimit: 10;
    }

    .st2 {
        fill: none;
        stroke: #C8001E;
        stroke-width: 4;
        stroke-miterlimit: 10;
    }

    .st3 {
        fill: #C8001E;
    }

    .st4 {
        fill: none;
        stroke: #006E46;
        stroke-width: 4;
        stroke-miterlimit: 10;
    }

    .st5 {
        fill: #006E46;
    }

    .st6 {
        fill: #FFFFFF;
    }

    .st7 {
        opacity: 0.5;
    }

    .st8 {
        opacity: 0.98;
        fill: none;
        stroke: #FFFFFF;
        stroke-width: 2;
        stroke-miterlimit: 10;
    }

    .st9 {
        opacity: 0.98;
        fill: none;
        stroke: #FFFFFF;
        stroke-width: 0.35;
        stroke-miterlimit: 10;
    }

    .st10 {
        opacity: 0.5;
        fill: none;
        stroke: #FFFFFF;
        stroke-width: 2;
        stroke-miterlimit: 10;
    }
}

.model-inactive {
    .st0 {
        opacity: 0.6;
        fill: none;
        stroke: #FFFFFF;
        stroke-width: 0.5;
        stroke-miterlimit: 10;
    }

    .st1 {
        opacity: 0;
        fill: #FFFFFF;
    }
}

.model-subnav-active {
    .st0 {
        fill: none;
        stroke: #FFFFFF;
        stroke-width: 2;
        stroke-miterlimit: 10;
    }

    .st1 {
        fill: none;
        stroke: #FFFFFF;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-miterlimit: 10;
    }

    .st2 {
        fill: none;
        stroke: #FFFFFF;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-miterlimit: 10;
    }

    .active {
        fill: #D20523;
        stroke: #D20523;
    }
}

.model4-symbols {
    position: absolute;
    cursor: pointer;
    opacity: 0;
    transition: opacity 1s;
    z-index: 1;

    &.symbol1 {
        right: 1%;
        top: 39.1%;

        &:hover + div + svg .level .st2 {
            fill: #D20523 !important;
        }
    }

    &.symbol2 {
        right: .7%;
        top: 56.2%;

        &:hover + svg .level .st1 {
            fill: #D20523 !important;
        }
    }
}
