$font-regular: 'AkzidenzGrotesk';
$font-bold: 'AkzidenzGrotesk-Bold';
$font-light: 'AkzidenzGrotesk-Light';


@font-face {
    font-family: 'AkzidenzGrotesk';
    src: url('../../font/AkzidenzGrotesk-Roman/AkzidenzGrotesk-Roman.eot');
    src: url('../../font/AkzidenzGrotesk-Roman/AkzidenzGrotesk-Roman.eot?#iefix') format('embedded-opentype'), url('../../font/AkzidenzGrotesk-Roman/AkzidenzGrotesk-Roman.woff') format('woff'), url('../../font/AkzidenzGrotesk-Roman/AkzidenzGrotesk-Roman.ttf') format('truetype'), url('../../font/AkzidenzGrotesk-Roman/AkzidenzGrotesk-Roman.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AkzidenzGrotesk-Light';
    src: url('../../font/AkzidenzGrotesk-Light/AkzidenzGrotesk-Light.eot');
    src: url('../../font/AkzidenzGrotesk-Light/AkzidenzGrotesk-Light.eot?#iefix') format('embedded-opentype'), url('../../font/AkzidenzGrotesk-Light/AkzidenzGrotesk-Light.woff') format('woff'), url('../../font/AkzidenzGrotesk-Light/AkzidenzGrotesk-Light.ttf') format('truetype'), url('../../font/AkzidenzGrotesk-Light/AkzidenzGrotesk-Light.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AkzidenzGrotesk-Bold';
    src: url('../../font/AkzidenzGrotesk-Bold/AkzidenzGrotesk-Bold.eot');
    src: url('../../font/AkzidenzGrotesk-Bold/AkzidenzGrotesk-Bold.eot?#iefix') format('embedded-opentype'), url('../../font/AkzidenzGrotesk-Bold/AkzidenzGrotesk-Bold.woff') format('woff'), url('../../font/AkzidenzGrotesk-Bold/AkzidenzGrotesk-Bold.ttf') format('truetype'), url('../../font/AkzidenzGrotesk-Bold/AkzidenzGrotesk-Bold.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
