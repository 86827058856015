.content-footer {
    width: 100%;
    min-height: 50px;
    background-color: #000;
    border-top: 1px solid rgba(255,255,255,.5);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;

    .content-wrapper {
        max-width: 1400px;
    }

    .footer {
        width: 100%;
        height: 50px;
        padding: 14px 0 14px 0;
        position: relative;
        display: flex;

        .footer-left {
            width: 210px;
            height: 100%;

            img, svg {
                height: 100%;
                width: auto;
            }

            .t-light {
                display: none;
            }
        }

        .footer-center {
            margin: 0 auto 0 auto;
            display: flex;

            .navigation {
                position: relative;
                font-size: 14px;
                line-height: 14px;
                display: flex;
                align-self: flex-end;

                .nav {
                    letter-spacing: 2px;
                    transition: color .3s;
                    color: rgba(255,255,255,.25);
                    font-family: $font-regular;

                    &:not(.active) {
                        cursor: pointer;
                    }

                    &.active, &:hover {
                        color: #fff;
                    }

                    &:not(:last-child) {
                        margin: 0 65px 0 0;
                    }
                }
            }
        }

        .footer-right {
            width: 210px;
            display: flex;
            justify-content: flex-end;
            align-self: flex-end;
            position: relative;

            .open-menu svg * {
                stroke: #fff;
            }

            .theme-mode {
                margin: 0 8px 0 30px;
                display: flex;

                .theme-check {
                    width: 22px;
                    height: 45px;
                    border: 1px solid rgba(255,255,255,.25);
                    border-radius: 23px;
                    cursor: pointer;
                    display: flex;

                    input:checked + span .theme-mode-button {
                        bottom: calc(100% - 18px);

                        .icon-theme-night {
                            display: none;
                        }

                        .icon-theme-day {
                            display: block;
                        }
                    }


                    > span {
                        position: relative;
                        margin: 2px;
                        width: 18px;
                        height: 43px;
                        border-radius: 9px;
                        overflow: hidden;


                        .theme-mode-button {
                            position: absolute;
                            left: 1px;
                            bottom: 3px;
                            width: 16px;
                            height: 16px;
                            border-radius: 8px;
                            background-color: rgba(255,255,255,.25);
                            transition: bottom .3s,left .3s;

                            svg {


                                &:last-child {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }

            .user {
                position: relative;
                display: flex;

                .open-menu {
                    width: 19px;
                    height: 7px;
                    margin: 0px 10px 0 0;
                    cursor: pointer;
                    display: flex;
                    align-self: center;
                    /*> svg:last-child {
                        display: none;
                    }*/
                }

                > input[type="checkbox"]:checked {
                    + label {
                        + .user-menu {
                            max-height: 500px;
                            border-bottom: 1px solid rgba(255,255,255,.25);
                        }
                    }

                    + label + .user-menu {
                        .icon-close-1 {
                            position: absolute;
                            top: -5px;
                            right: -15px;
                            z-index: 2;
                            display: block;
                            width: 15px;
                            height: 15px;

                            .st0 {
                                stroke: #fff;
                            }
                        }
                    }
                }

                .user-menu {
                    position: absolute;
                    bottom: 18px;
                    right: calc(100% - 20px);
                    width: 250px;
                    overflow: hidden;
                    max-height: 0;
                    transition: max-height .3s;
                    background-color: rgba(0,0,0,.9);
                    color: #fff;
                    z-index: 1;
                    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.25);
                    display: flex;
                    flex-wrap: wrap;

                    > *:first-child {
                        position: relative;
                    }

                    > * {
                        width: calc(100% - 50px);
                        padding: 15px 25px;
                        font-family: $font-light;
                        font-weight: 300;
                        line-height: 22px;

                        &:first-child, &:last-child {
                            padding: 0;
                            margin: 15px 25px;
                        }

                        &:last-child {
                            cursor: pointer;
                        }

                        &.user-options {
                            border-bottom: 1px solid rgba(255,255,255,.25);
                            border-top: 1px solid rgba(255,255,255,.25);
                            display: flex;
                            flex-wrap: wrap;

                            a {
                                font-family: $font-light;
                                font-weight: 300;
                                width: 100%;
                            }
                        }
                    }
                }

                .user-avatar {
                    position: relative;

                    .notification {
                        position: absolute;
                        top: -1px;
                        right: -6px;
                        width: 19px;
                        height: 19px;
                        border-radius: 18px;
                        overflow: hidden;
                        background-color: #D20423;
                        color: #fff;
                        text-align: center;
                        line-height: 18px;
                        font-size: 12px;
                        display: flex;
                        flex-direction: column;
                        align-content: center;
                        justify-content: center;
                    }

                    .avatar {
                        overflow: hidden;
                        width: 41px;
                        height: 41px;
                        border-radius: 50px;
                        border: 2px solid #fff;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }

            .language-switcher {
                position: absolute;
                width: 60px;
                left: 100%;
                bottom:0;
                display: flex;

                .language {
                    color: rgba(255, 255, 255, 0.25);

                    &:not(.active) {
                        cursor: pointer;
                    }

                    &:not(:last-child):after {
                        content: '|';
                        margin: 0 5px;
                    }

                    &.active {
                        color: #fff;
                    }
                }
            }
        }
    }
}
