@import "../../../Assets/scss/abstracts/variables";

.loader-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: rgba(20,20,20,.9);

    .loader {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 600px;
        max-width: calc(100% - 40px);
        height: 5px;
        text-align: center;
        color: #fff;

        svg {
            width: 100%;
            height: 100%;
            margin-bottom: 15px;
        }
    }
}