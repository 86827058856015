/*structure this better after feedback */

.darkMode {
    div,
    .btn {
        color: #fff;
    }

    ::-webkit-scrollbar-track {
        border-color: #fff;
        background-color: rgba(0, 0, 0, .75);
    }

    ::-webkit-scrollbar-thumb {
        background-color: #fff;
    }

    div.bq-element div.topic {
        &.active,
        &:hover {
            >div:not(.number) p {
                color: #335f9b !important;
            }
        }
    }
}

.lightMode {
    /*background-color: $primary-color !important;*/
    color: $themeBG !important;

    body {
        /*background-image: url("../../images/theme/day/bg_daymode.png");*/
        background-image: url("../../images/theme/day/bg-wave-lines-white-v2.webp");
    }
    .logoBanner{
        background-image: url(../../images/theme/day/holos-logo-dark.svg);
        width: 92px;
        height: 30px;
        position: fixed;
        top: 10px;
        left: 17px;
    }

    
    #root {
        background-image: url("../../images/temp/bg-light.png");

        &:before {
            background-image: url("../../images/temp/bg-light.png");
        }
        /*background-image: none;*/
    }

    .loader-container {
        background-color: rgba(235,235,235,.9);

        .loader {
            color: $themeBG;
        }
    }

    .popup-overlay {
        background-color: rgba(235,235,235,.9) !important;

        .popup {
            background-color: #fff !important;

            .icon-close-1 .st0 {
                stroke: $themeBG !important;
            }
        }
    }

    .tile-container .tile-title {
        border-color: $themeBG !important;

        svg {
            .st0, .st1 {
                stroke: $themeBG !important;
            }
        }
    }
    /*footer*/
    .content-footer {
        background-color: #F5F5F5 !important;
        color: $themeBG !important;
        border-color: #000;

        .footer {
            color: rgba(0,0,0,.25);

            .footer-left {
                svg {
                    .st0 {
                        fill: #505050;
                    }

                    .st24 .st0 {
                        fill: #000;
                    }
                }
            }

            .footer-center .navigation .nav {
                color: rgba(0, 0, 0, 0.25);

                &.active, &:hover {
                    color: #000;
                }
            }

            .footer-right {
                .user {
                    .open-menu .icon-arrow-up-1 .st0 {
                        stroke: #000;
                    }

                    .user-avatar .avatar, .theme-mode .theme-check {
                        border-color: #000;
                    }

                    .user-menu {
                        background-color: rgba(255, 255, 255, 0.9);
                        color: #000;

                        .user-options {
                            border-color: rgba(0, 0, 0, 0.25);
                        }

                        .icon-close-1 .st0 {
                            stroke: #000 !important;
                        }
                    }
                }

                .theme-mode .theme-check {
                    border-color: rgba(0, 0, 0, 0.25);

                    > span .theme-mode-button {
                        background-color: rgba(0, 0, 0, 0.75);
                    }

                    span svg {
                        /*.st0 {
                        fill: #000;
                    }

                    .st1 {
                        fill: #f5f5f5;
                    }*/
                    }
                }

                .language-switcher .language {
                    color: rgba(0, 0, 0, 0.25);

                    &.active {
                        color: $themeBG;
                    }
                }
            }
        }
    }
    /*tiles*/
    .tile .tile-inner {
        .progress p {
            color: #e3e3e3;
        }

        .content {
            .tile-content {
                .t-content-right, .t-embed {
                    color: #fff;
                }

                .t-embed svg .st0 {
                    stroke: #fff;
                }
            }

            .bb-icons .bb-icon > div p.val {
                color: #fff;
            }
        }

        .embed {
            background-color: #f5f5f5;

            .e-top div .st0 {
                stroke: #000;
            }

            .e-bottom {
                color: #000;

                .e-text:nth-child(2) {
                    border-color: rgba(0,0,0,.75);
                }
            }
        }
    }

    .tile-container .tile-content .tile-pagination > div {
        background-color: rgba(0, 0, 0, 0.5);

        &.active {
            background-color: rgba(0, 0, 0, 1);
        }
    }

    .load-more-container .load-more {
        /*color: #fff;*/
        background-color: rgba(255, 255, 255, 0.7);
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);

        .st0 {
            fill: $themeBG;
        }
    }
    /*bite&topic*/
    .alias-topic, .alias-bite {
        .banner {
            .banner-content, .banner-bar {
                color: #fff;
            }
        }

        .back-button svg .st0 {
            stroke: #000;
        }

        .page-body .p-overview > .bar,
        .page-body .p-content > .bar,
        .page-body .p-overview > .bar,
        .page-body .p-content > .bar,
        .page-body .p-content .bite-progress .bar {
            background-color: rgba(0,0,0,.25);
        }

        .bite-quizz .bq-title {
            border-color: #000;
        }

        .bite-quizz {
            .bq-element {
                border-color: rgba(0,0,0,.25);
            }

            .bq-icon {
                .ico-quizz {
                    .a, .c {
                        fill: #000;
                    }
                }

                .ico-exercise, .ico-time {
                    .a {
                        fill: #000;
                    }
                }
            }
        }
    }
    /*bite*/
    .page-body .btn-start {
        color: #fff;
    }

    .icon-mode-read .st0 {
        fill: #000;
    }

    .icon_fullscreen .st0 {
        stroke: #000;
    }

    .alias-bite .page-body {
        .p-overview {
            /*#modeToggle:checked + div .modes .mode-read svg .st0 {
                fill: #000;
            }*/
            .icon-mode-media .st0 {
                fill: #000;
                stroke: #000;
            }

            .mode-container .media-controls .mc-container .mc-controls {
                .mc-buttons {
                    .btn-mc.mc-replay {
                        border-color: #000;
                    }

                    svg {
                        .st0, .st1 {
                            fill: #000;
                        }
                    }
                }

                .mc-progress-tracker {
                    .mc-seekbar:before {
                        background-color: #000;
                    }

                    .mc-remaining {
                        color: rgba(0, 0, 0, 0.5)
                    }
                }
            }

            .bite-quizz .bq-element .topic .number p {
                border-color: $themeBG;
            }

            .assets-container {
                border-color: #000;

                .a-menu {
                    border-color: #000;

                    div {
                        color: rgba(0, 0, 0, 0.25);
                        border-color: rgba(0, 0, 0, 0.5) !important;

                        &.active, &:hover {
                            color: #000 !important;
                        }
                    }

                    label {


                        &:hover {
                            color: #000 !important;
                        }

                        &:last-child {
                            border-color: #000;
                            color: rgba(0,0,0,.25);
                        }
                    }
                }

                .a-row > span > span:last-child {
                    color: rgba(56,56,56,1);
                }


                .a-container .a-row {
                    border-color: rgba(0,0,0,.25);
                }

                .element-container {
                    background-color: #fff;

                    .ec-back .st0 {
                        stroke: #000;
                    }
                }

                .a-icon {
                    background-color: transparent;

                    .st0 {
                        stroke: #000;
                    }

                    .st1 {
                        fill: #000;
                    }
                }
            }

            #assetsToggle:checked + div .a-menu label:last-child {
                color: #000;
            }

            #assetsToggle:checked + div .a-menu label:first-child {
                color: rgba(0,0,0,.25);
            }
        }

        .p-content {
            .bite-player {

                &:before, &:after {
                    background-color: #000;
                }

                .arrow .st0 {
                    stroke: #000;
                }

                .overview-element .ico-time .a {
                    fill: #000;
                }
            }

            .bite-progress .bar > div p:first-child {
                border-color: #000;
            }

            &.isMedia .step .play-this > span.highlight {
                color: #fff;
            }
        }

        &.expanded-view .p-content {
            .bite-player .step {
                background-color: #fff;
                color: $themeBG;
            }

            + .p-overview .mode-container .expand .expand-icon svg:last-child .st0 {
                stroke: #000;
            }
        }

        &.sticky .p-content .bite-progress {
            background-color: #f5f5f5;
        }
    }
    /*slider - highlights*/
    .alias-home .content-slider .slider {
        .slide-container {

            .btn {
                color: #fff;
            }
        }

        .slide-navigation span {
            border-color: #000;

            &.active {
                background-color: #000;
            }
        }
    }
    /*models*/
    .icon_circle_2 .st0 {
        stroke: #000;
    }

    .icon_circle_1 .st0, .icon_circle_1 .st1 {
        stroke: #000;
    }




    .model-carousel {
        .slider {
            .scroll-indication {
                color: #000;
                background-color: rgba(255,255,255,.7);

                .scroll-indication-arrow {
                    svg {
                        .st0 {
                            stroke: #000;
                        }
                    }
                }
            }

            .slider-navigation .slider-navigation-container {
                .line {
                    background-color: #000;
                }

                .model1-icon, .model2-icon, .model3-icon, .model4-icon, .model5-icon {
                    .cls-1 {
                        fill: #000;
                    }
                }

                .slide-nav {
                    span {
                        color: #000;
                    }

                    .st0, .st1, .st2 {
                        stroke: #000;
                    }
                }
            }

            .model-container {
                > div.active .slide-container {
                    .model {
                        &.model3 {
                            background-color: rgba(0,0,0,.7);
                            border-radius: 50%;
                        }

                        &.model4 .outer-titles .st18 {
                            fill: #000;
                        }
                    }

                    &.magnify {
                        background-color: #fff;

                        .fullscreenClose .st0 {
                            stroke: #000;
                        }

                        .close-zoom.pos-bottom .scroll-indication {
                            background-color: rgba(0, 0, 0, 0.7);
                            color: #fff;

                            .st0 {
                                stroke: #fff;
                            }
                        }
                    }
                }

                &:hover > .active .icon-zoom {
                    .st0, .st1 {
                        stroke: #000;
                    }
                }
            }
        }

        .view-toggle {
            label {
                border-color: #000;

                svg .st0 {
                    stroke: #000;
                }
            }
        }

        .model-sub-navigation .msn-outer {

            .msn-outer-container {
                border-color: rgba(0,0,0,.5);
                background-color: rgba(255,255,255,.7);

                .msn-section {
                    border-color: rgba(0,0,0,.5);
                }
            }
        }
    }

    .modelActive {
        .level.l1:hover, .level.active {
            .st0 {
                fill: #fff !important;
            }
        }

        .st1, .st2, .st3 {
            stroke: #000;
        }
    }

    .model-inactive {
        .st0 {
            stroke: #000;
        }
    }

    .model-carousel .slider .model-container > div svg.modelActive {
        rect, polygon, path:not(.line-mid) {
            fill: #000;
        }

        .level:not(.active) {
            .st1 {
                fill: none;
            }

            &:not(:hover) .st2 {
                fill: none;
            }
        }
    }

    .icon-zoom .st1 {
        stroke: #000;
    }
    /*.model4-symbols.active, .model4-symbols:hover {
        color: #fff;
    }*/
    //model 2 text color
    #Text {
        .st8 {
            fill: #000;
        }

        .st9 {
            stroke: #000;
        }
    }
    /*scrollbars*/
    ::-webkit-scrollbar-track {
        border-color: rgba(0,0,0,.75);
        background-color: #fff;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #000;
    }
    /*bot*/
    .adativecards-container {
        .arrow, .arrow:hover {
            .st0 {
                stroke: #fff !important;
            }
        }

        .adaptivecards-container-inner .card .t-text > p:not(:first-child) {
            color: #fff;
        }
    }

    .chat-container .chat #chat .css-990gl9 .css-ca0rlf ul.css-7c9av6 li.css-1qyo5rb > div .webchat__stackedLayout__content .webchat__row.message .bubble .webchat__bubble__content,
    .chat-container .chat #chat .css-990gl9 .css-ca0rlf ul.css-7c9av6 li.css-1qyo5rb > div .webchat__stackedLayout__content .webchat__row.attachment .bubble .webchat__bubble__content {
        color: $themeBG;
    }

    .chat-container .chat #chat > .arrow svg .st0 {
        stroke: $themeBG;
    }

    .chat-container .chat #chat .css-eycyw2 > .main {
        box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.25);
    }

    .chat-container {
        box-shadow: none;
    }

    .chat-container .chat #chat .css-990gl9 .css-ca0rlf ul.css-7c9av6 li.css-1qyo5rb > div .webchat__stackedLayout__content .webchat__row.message .ac-pushButton,
    .chat-container .chat #chat .css-990gl9 .css-ca0rlf ul.css-7c9av6 li.css-1qyo5rb > div .webchat__stackedLayout__content .webchat__row.attachment .ac-pushButton {
        border-color: $themeBG;
        color: $themeBG;
    }

    .chat-container .chat #chat .css-990gl9 .css-ca0rlf ul.css-7c9av6 li.css-1qyo5rb > div .webchat__stackedLayout__content .webchat__row.message .ac-pushButton:hover,
    .chat-container .chat #chat .css-990gl9 .css-ca0rlf ul.css-7c9av6 li.css-1qyo5rb > div .webchat__stackedLayout__content .webchat__row.attachment .ac-pushButton:hover {
        color: #fff;
        background-color: $themeBG;
    }
    /*.chat-notification {
        background-color: rgba(255, 255, 255, 0.3)
    }*/
    /*chat*/
    .chat-container {
        background-color: #f5f5f5;



        .chat {
            .chat-button {
                background-color: #f5f5f5;

                .button-chat {
                    &:hover .icon-chat .st0 {
                        fill: #000;
                    }

                    .icon-chat .st0 {
                        fill: transparent;
                        stroke: #000;
                    }
                }

                .button-search {

                    &:hover .icon-search {
                        .a, .b {
                            fill: #000;
                        }
                    }

                    .icon-search .a {
                        fill: #000;
                        stroke: transparent;
                    }
                }
            }

            .botchat {
                > div:nth-child(2) {
                    background-color: transparent;

                    > div:nth-child(2) ul li > div .webchat__stacked-layout__content .webchat__stacked-layout__message-row .webchat__bubble .webchat__bubble__content {
                        color: $themeBG !important;
                    }

                    > div:nth-child(2) ul li > div .webchat__stacked-layout__content .webchat__stacked-layout__message-row .webchat__bubble.webchat__bubble--from-user .webchat__bubble__content {
                        color: rgba(0,0,0,.3) !important;
                    }

                    > div:nth-child(2) > div:nth-child(4) ul > li > .webchat__basic-transcript__activity-box > .webchat__stacked-layout .webchat__stacked-layout__content .webchat__stacked-layout__attachment-row .ac-textBlock {
                        color: $themeBG !important;
                    }

                    > div:nth-child(2) ul li {
                        border-color: rgba(0,0,0,.15) !important;



                        &:last-child {
                            border-color: rgba(0,0,0,.5) !important;
                        }

                        > div .ac-container button {
                            border-color: $themeBG;

                            > div {
                                color: $themeBG;
                            }

                            > .webchat__basic-transcript__activity-box > .webchat__stacked-layout .webchat__stacked-layout__content .webchat__stacked-layout__attachment-row .ac-textBlock {
                                color: #000 !important;
                            }

                            &:hover {
                                background-color: $themeBG !important;

                                > div {
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }

            .search-container {
                > div:nth-child(2) {
                    background-color: transparent;

                    ul li {
                        border-color: rgba(0,0,0,.15);



                        &:last-child {
                            border-color: rgba(0,0,0,.5);
                        }

                        > div > p, {
                            color: $themeBG !important;
                        }
                    }
                }

                .arrow svg .st0 {
                    stroke: $themeBG;
                }
            }


            #chat, .search-container {
                background-color: #f5f5f5;

                .main, .webchat__send-box__main {
                    background-color: #f5f5f5;

                    input {
                        color: $themeBG !important;
                    }
                }
            }
        }
    }
}