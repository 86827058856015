@import "../../../../Assets/scss/abstracts/variables";


.alias-bite {
    .page-body {
        .bar {
            transition: background-color .3s;

            &.active {
                background-color: #D20423 !important;
            }
        }
        /*.p-overview, .p-content {
            .step:not(.active) {
                display: none;
            }
        }*/
        .p-overview {

            .bar {
                width: 100%;
            }

            &.multimedia-mode .mode-container {
                .modes {
                    .mode-read svg .st0 {
                        fill: #fff;
                    }

                    .mode-multi svg .st0 {
                        fill: #D20423;
                        stroke: #d20243;
                    }
                }

                .media-controls {
                    display: block;
                }
            }

            .mode-container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin: 14px 0 0 0;

                .modes {
                    text-align: center;
                    display: flex;
                    flex-shrink: 0;

                    > div {
                        display: flex;

                        svg {
                            width: auto;
                            height: 23px;
                            cursor: pointer;

                            .st0 {
                                transition: fill .3s;
                            }

                            &:hover .st0 {
                                fill: #D20423 !important;
                                stroke: #d20243 !important;
                            }
                        }

                        &.mode-read svg .st0 {
                            fill: #D20423;
                        }
                    }
                    /*.mode-multi {
                        border-left: 1px solid rgba(255,255,255,.2);
                        margin: 0 0 0 30px;
                        padding: 0 0 0 30px;
                    }*/
                    p {
                        font-size: 12px;
                        line-height: 14px;
                        font-family: $font-bold;
                        font-weight: 700;
                        margin: 0 0 0 10px;
                        align-self: center;
                    }
                }

                .expand {
                    height: 23px;
                    text-align: center;
                    justify-self: right;

                    .expand-icon {
                        width: 23px;
                        height: 23px;
                        display: inline-block;
                        cursor: pointer;

                        svg:first-child {
                            display: block;
                        }

                        svg:last-child {
                            display: none;
                        }

                        .st0 {
                            transition: stroke .3s;
                        }

                        &:hover .st0 {
                            stroke: #D20423;
                        }
                    }
                }


                .media-controls {
                    width: 100%;
                    display: none;



                    .mc-container {

                        .hide {
                            display: none;
                        }

                        .mc-controls {
                            width: 100%;

                            .mc-buttons {
                                width: 100%;
                                display: flex;
                                justify-content: center;
                                flex-wrap: wrap;
                                position: relative;
                                margin: 20px 0 0 0;

                                .btn-mc {
                                    height: 40px;
                                    width: 40px;
                                    justify-content: center;
                                    align-items: center;
                                    display: flex;

                                    svg {
                                        height: 18px;
                                    }

                                    &.mc-replay {
                                        position: absolute;
                                        left: 0;
                                        width: auto;
                                    }

                                    &.mc-playPause {
                                        border-radius: 50%;
                                        background-color: #D20423;
                                        margin: 0 25px 0 25px;
                                    }

                                    > svg {
                                        width: auto;
                                        cursor: pointer;
                                    }
                                }

                                .mc-buttons-main {
                                    display: flex;
                                    justify-content: center;
                                }
                            }

                            .mc-progress-tracker {
                                width: 100%;
                                display: flex;
                                flex-wrap: wrap;

                                .mc-seekbar {
                                    width: 100%;
                                    height: 1px;
                                    padding: 3px 0;
                                    position: relative;
                                    cursor: pointer;
                                    display: flex;
                                    margin: 20px 0 0 0;

                                    &:before {
                                        content: '';
                                        width: 100%;
                                        height: 2px;
                                        background-color: #fff;
                                        align-self: center;
                                    }

                                    .mc-played-line {
                                        height: 3px;
                                        width: 0;
                                        position: absolute;
                                        left: 0;
                                        background-color: #335f9b;
                                        top: 2px;
                                    }

                                    > div:last-child {
                                        width: 7px;
                                        height: 7px;
                                        background-color: #D20423;
                                        border-radius: 50%;
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                    }
                                }


                                .mc-played {
                                    font-size: 12px;
                                    line-height: 12px;
                                    margin: 5px auto 0 0;
                                }

                                .mc-remaining {
                                    margin: 5px 0 0 0;
                                    font-size: 12px;
                                    line-height: 12px;
                                    color: rgba(255,255,255,.5);
                                }
                            }
                        }
                    }
                }

                .audio-player {
                    .popup {
                        width: 320px;
                        height: auto;
                        min-height: 510px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding: 65px 20px 65px 20px;
                        border: 0.5px solid #888888;

                        .thumbnail {
                            width: 100%;
                            padding-bottom: 87%;
                            background-repeat: no-repeat;
                            background-position: 83% center;
                            background-size: cover;
                            margin: 0 0 25px 0;
                            border-radius: 5px;
                        }

                        .topic-title {
                            font-size: 22px;
                            line-height: 25px;
                            font-family: $font-bold;
                            position: relative;
                            width: 100%;
                            /*min-height: 25px;
                            overflow: hidden;*/
                            text-align: center;
                        }

                        .bite-title {
                            margin: 0 0 40px 0;
                            font-weight: 700;
                            font-size: 15px;
                            line-height: 25px;
                            color: #B6B6B6;
                            overflow: hidden;
                            position: relative;
                            width: 100%;
                            min-height: 25px;
                        }

                        .bite-title p {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            margin: 0;
                            line-height: 30px;
                            text-align: center;
                            /* Starting position */
                            -moz-transform: translateX(100%);
                            -webkit-transform: translateX(100%);
                            transform: translateX(100%);
                            /* Apply animation to this element */
                            -moz-animation: scroll-left 20s linear infinite;
                            -webkit-animation: scroll-left 20s linear infinite;
                            animation: scroll-left 20s linear infinite;
                        }


                        .media-controls {
                            display: flex;
                            justify-content: center;

                            .mc-container {
                                width: 100%;
                            }
                        }
                    }
                }
            }

            .bite-quizz {
                margin: 35px 0 0 0;
                font-size: 18px;
                line-height: 25px;

                &.topics .bq-element {
                    flex-direction: column;
                }

                .bq-title {
                    font-family: $font-regular;
                    font-weight: normal;
                }

                .bq-element {


                    &.no-border {
                        border-bottom: none;
                    }

                    .topic {
                        display: flex;
                        margin: 0 0 10px 0;
                        cursor: pointer;

                        &.active, &:hover {
                            > div:not(.number) {
                                color: #335f9b;
                            }
                        }

                        > div {
                            transition: color .3s;
                        }

                        .number {
                            font-size: 14px;
                            display: flex;
                            margin: 5px 0 0 0;

                            p {
                                width: 15px;
                                height: 15px;
                                border: 1px solid #fff;
                                border-radius: 50%;
                                margin: 0 10px 0 0;
                                text-align: center;
                                font-size: 10px;
                                display: flex;
                                justify-content: center;
                                line-height: 17px;
                                align-content: center;
                                flex-direction: column;
                                /* line-height: 18px;*/
                            }
                        }

                        strong {
                            font-family: $font-regular;
                            font-weight: normal;
                        }
                    }
                }
            }


            .assets-container {
                margin: 75px 0 0 0;
                height: 240px;
                width: 100%;
                border-bottom: 2px solid rgba(255,255,255,.5);
                position: relative;
                padding: 0 0 9px 0;

                .a-menu {
                    width: 100%;
                    border-bottom: 1px solid rgba(255,255,255,.5);
                    padding-bottom: 9px;
                    font-weight: 700;
                    font-family: $font-bold;
                    display: flex;

                    div {
                        cursor: pointer;
                        transition: color .3s;
                        font-size: 12px;
                        line-height: 12px;
                        letter-spacing: 3px;
                        color: rgba(255, 255, 255, 0.25);

                        &:hover, &.active {
                            color: #fff !important;
                        }

                        &:not(:first-child) {
                            margin: 0 0 0 15px;
                            padding: 0 0 0 15px;
                            border-left: 1px solid rgba(255,255,255,.5);
                        }
                    }
                }

                .a-container {
                    margin: 9px 0;
                    height: calc(100% - 40px);
                    width: calc(100% - 10px);
                    padding: 0 5px 0 0;
                    overflow-y: scroll;

                    .a-row {
                        border-bottom: 1px solid rgba(255,255,255,.25);
                        width: calc(100% - 10px);


                        .a-left {
                            width: calc(30% - 10px);
                            margin: 0 0 0 10px;
                            font-size: 11px;
                            line-height: 15px;
                        }

                        .a-right {
                            width: 70%;
                        }
                    }
                }

                .a-icon {
                    position: absolute;
                    top: calc(100% + 7px);
                    left: 0;
                    cursor: pointer;
                }

                .a-row {
                    width: 100%;
                    padding: 0 0 0 10px;
                    height: auto;
                    padding: 8px 0;
                    cursor: pointer;
                    transition: background-color .3s;
                    display: flex;

                    &:hover {
                        background-color: #4B6EB5;
                    }

                    > span {
                        display: flex;
                        flex-direction: column;
                    }

                    > span > span {
                        width: 100%;

                        &:last-child {
                            color: #969696;
                        }
                    }

                    .a-right {
                        font-size: 12px;
                        line-height: 15px;

                        > span:first-child {
                            font-family: $font-bold;
                        }

                        > span:last-child {
                            font-family: $font-light;
                        }
                    }
                }


                .notes-container {
                    .a-icon {
                        width: 14px;
                        height: 16px;
                    }
                }

                .uploads-container {
                    .a-icon {
                        width: 13px;
                        height: 15px;
                    }
                }

                > .a-container:not(.active) {
                    display: none;
                }

                .element-container {
                    position: absolute;
                    top: 31px;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    height: calc(100% - 30px);
                    width: 100%;
                    background-color: $themeBG;
                    transition: max-width .5s;
                    max-width: 0;
                    overflow: hidden;

                    .a-icon {
                        opacity: 0;
                        transition: opacity .3s .3s;
                    }

                    &.active {
                        max-width: 500px;

                        + .a-icon {
                            display: block;
                        }
                    }

                    .ec-back {
                        width: calc(100% - 20px);
                        padding: 6px 10px 10px 10px;
                        border-bottom: 1px solid rgba(255,255,255,.25);
                        font-size: 12px;
                        font-family: $font-bold;
                        font-weight: 700;
                        cursor: pointer;
                        display: flex;
                        height: 13px;

                        svg {
                            width: 3px;
                            height: 13px;
                            margin: 3px 10px 0 0;

                            .st0 {
                                stroke: #fff;
                            }
                        }
                    }

                    .ec-content {
                        width: calc(100% - 10px);
                        height: calc(100% - 50px);
                        margin: 10px 0;
                        padding: 0 10px 0 0;
                        overflow-y: scroll;
                        display: none;

                        &.active {
                            display: block;
                        }

                        .ec-inner {
                            width: calc(100% - 10px);
                            padding: 0 0 0 10px;
                            height: auto;
                            font-size: 12px;

                            .ec-title {
                                font-family: $font-bold;
                                font-weight: 700;
                                margin: 0 0 10px 0;
                                width: 100%;
                            }

                            .ec-text {
                                color: #969696;
                                width: 100%;
                            }
                        }

                        &.ec-typing {
                            overflow-y: hidden;
                            width: 100%;
                            padding: 0;

                            .ec-title {
                                color: #969696;
                            }

                            textarea {
                                background-color: transparent;
                                border: none;
                                min-height: 135px;
                                margin: 0;
                                padding: 0;
                                outline: 0;
                                font-family: $font-regular;
                                resize: none;
                                overflow-y: scroll;
                            }
                        }

                        &.ec-file .ec-inner {
                            padding: 0;
                            width: 100%;

                            .a-row {
                                padding: 8px 0 8px 10px;
                                width: calc(100% - 10px);

                                .a-left {
                                    width: 19px;
                                    margin: 2px 6px 0 0;
                                    height: 11px;
                                }

                                .a-right {
                                    width: calc(100% - 25px)
                                }
                            }
                        }
                    }

                    + .a-icon {
                        display: none;
                    }
                }

                .a-icon {
                    width: 15px;
                    height: 15px;
                    background-color: $themeBG;

                    svg {
                        width: 13px;
                        height: 14px;
                    }
                }
            }
        }

        .p-content {
            margin: 0 50px 0 0;

            > p {
                margin: 12px 0 40px;
                font-size: 12px;
            }

            .bite-progress {
                width: 100%;
                position: relative;
                display: flex;

                .progress-container {
                    width: calc(100% - 2px);
                    position: relative;
                    display: flex;
                }

                &.cursor-default .bar > div {
                    cursor: default;
                }

                .bar {
                    position: relative;
                    //width: calc((100% - 5px) / 6);
                    background-color: #ccc;

                    &:not(.total):before {
                        content: none;
                    }

                    &:not(:first-child):not(.total) {
                        margin: 0 0 0 1px;
                    }

                    > div {
                        position: absolute;
                        left: 0;
                        bottom: calc(100% + 5px);
                        font-size: 14px;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        cursor: pointer;

                        p {
                            width: 15px;
                            height: 15px;
                            border: 1px solid #fff;
                            border-radius: 50%;
                            margin: 0 0 0 0;
                            text-align: center;
                            font-size: 10px;
                            display: flex;
                            justify-content: center;
                            line-height: 17px;
                            align-content: center;
                            flex-direction: column;
                            /*line-height: 18px;*/
                        }
                    }
                    /*&:first-child div p {
                        display: none;
                    }*/
                }

                .total {
                    width: 0;
                    max-width: calc(100% - 1px);
                    height: 3px;
                    position: absolute;
                    right: 0;
                    left: 0;
                    top: calc(100% + 1px);
                    background-color: transparent;
                    background-color: #2E61B2;
                    transition: width .3s;

                    &.started {
                        border-right: 1px solid #fff;
                    }
                }
            }

            &.isMedia .step .play-this {
                /*display: flex;*/
                > span {
                    opacity: .5;
                    transition: background-color .3s;

                    &.highlight {
                        background-color: #D20423;
                    }

                    &.read {
                        opacity: 1;
                    }
                }
            }

            .step {
                width: 100%;

                &.overview-element {
                    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
                    padding: 0 0 25px;

                    .elem-title {
                        font-size: 18px !important;
                    }
                }

                .elem-text {
                    /*display: initial !important;*/
                }

                .title {
                    width: 100%;
                    margin: 0 0 15px 0;
                    font-family: $font-bold;
                    font-weight: 700;
                    font-size: 15px;
                    line-height: 20px;
                }

                .preview {
                    width: 100%;
                    height: auto;
                    background-color: #2E61B2;
                    display: flex;

                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                .bottom {
                    width: 100%;
                    margin: 45px 0 0 0;
                    display: flex;

                    > div:first-child {
                        width: calc(((100% / 3) * 2) - 13px);
                        margin: 0 26px 0 0;

                        .bq-element {
                            border-bottom: none;

                            ul, ol {
                                width: calc(100% - 16px);
                                column-count: 2;
                                column-gap: 50px;


                                li {
                                    width: 100%;
                                    height: auto;
                                    margin-bottom: 5px;

                                    strong {
                                        font-family: $font-regular;
                                        font-weight: normal;
                                    }
                                }
                            }

                            ul {
                                list-style-type: square;
                            }
                        }
                    }

                    > div:last-child {
                        width: calc((100% / 3) - 13px);
                    }
                }
            }

            .bite-player {
                position: relative;
                min-height: 700px;

                .step {
                    .elem-image {
                        display: flex;
                        position: relative;

                        &:not(:last-child) {
                            margin: 0 0 40px 0;
                        }

                        img {
                            width: 100%;
                            height: auto;
                        }

                        .elem-text {
                            position: absolute;
                            right: 50px;
                            top: 0;
                            bottom: 0;
                            width: 70%;
                            display: flex;
                            justify-content: center;

                            p {
                                margin: 0;
                            }
                        }

                        + .elem-title-wrapper {
                            margin-top: 50px;
                        }
                    }

                    .elem-images + .elem-title-wrapper {
                        margin-top: 60px;
                    }

                    .elem-title {
                        flex-shrink: 0;
                        font-size: 22px;
                        line-height: 25px;
                        font-family: $font-bold;
                        font-weight: 700;
                        margin: 50px 0 20px 0;

                        + .elem-image, + .elem-images {
                            margin-top: 40px;
                        }
                    }

                    .elem-title-wrapper {


                        + .elem-image, + .elem-images {
                            margin-top: 40px;
                        }
                    }
                    /*.elem-download {
                        margin: 60px 0 0 50px;
                    }*/
                    .elem-text {
                        flex-direction: column;
                        font-size: 18px;
                        line-height: 25px;
                        margin: 0 0 0 auto;

                        p:not(:last-child) {
                            margin: 0 0 20px 0;
                        }

                        + .elem-image, + .elem-images {
                            margin-top: 40px;
                        }

                        ol, ul {
                            margin: 0 0 20px 0;
                            padding: 0 0 0 25px;

                            > ul {
                                margin-top: 10px;
                            }

                            li {
                                margin-bottom: 10px;
                                padding-left: 5px;
                            }
                        }

                        ul {
                            list-style-type: square;
                        }

                        a, &.link-style {
                            font-family: $font-regular;
                            text-decoration: underline;
                            cursor: pointer;
                        }

                        &.quizz-link {
                            cursor: pointer;
                            color: #2E61B2;
                        }

                        .time {
                            font-weight: normal;
                            font-size: 16px;
                            display:flex;
                            svg {
                                height: 24px;
                                width: auto;
                                margin: 0 10px 0 0 ;
                            }
                        }
                    }

                    .margin-t {
                        margin-top: 20px !important;
                    }
                    /*temporary until pages are made clickable*/
                    .elem-images .elem-image:not(:last-child) {
                        display: none;
                    }

                    iframe.elem-quiz {
                        width: 100%;
                        min-height: 100%;
                        border: none;
                    }
                }
            }
        }

        .popup-overlay {
            display: none;
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(106,106,106, 0.85);
            z-index: 11;
            backdrop-filter: blur(3px);
            -webkit-backdrop-filte: blur(3px);

            .popup {
                width: 50%;
                height: 50%;
                background-color: $themeBG;
                margin: auto;
                padding: 50px;
                position: relative;
                overflow: hidden;
                box-shadow: 0px 1px 21px 0 rgba(0, 0, 0, 0.25);

                .icon-close-1 {
                    position: absolute;
                    top: 15px;
                    right: 15px;
                    width: 15px;
                    height: 15px;
                    cursor: pointer;

                    .st0 {
                        stroke: #fff;
                    }
                }
            }

            &.active {
                display: flex;
            }
        }

        &.expanded-view {
            .p-overview .mode-container .expand .expand-icon {
                position: fixed;
                top: 7px;
                right: 12px;
                width: 25px;
                height: 25px;
                z-index: 12;

                svg:first-child {
                    display: none;
                }

                svg:last-child {
                    display: block;
                }
            }

            .p-content .bite-player {
                position: fixed;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: $themeBG;
                z-index: 11;
                min-height: inherit;
                padding: 0;
                width: 100%;
                overflow-y: auto;

                .step {
                    width: auto;
                    background-color: $themeBG;
                    padding: 35px 35px 10px 35px;

                    .elem-text {
                        > *:last-child, li:last-child {
                            margin: 0;
                        }
                    }
                }
            }
        }

        &.sticky {
            .p-content .bite-progress {
                position: fixed;
                top: 0;
                padding: 35px 0 0 0;
                background-color: #000;
                left: 0;
                z-index: 1;
                justify-content: center;

                .progress-container {
                    max-width: 1400px;
                    width: 100%;
                }
            }

            .p-overview {
                position: absolute;
                top: 0;
                right: 75px;
                width: calc((100% - 155px) * 0.25);
                padding: 0 5px 0 0;
                overflow-y: auto;

                .bar {
                    display: none;
                }
            }
        }

        .btn-start {
            //only used in bite now
            text-align: center;
            width: auto;
            min-width: 100px;
            padding: 10px 40px;
            font-family: $font-bold;
            font-weight: 700;
            cursor: pointer;
            background-color: #2E61B2;
            margin: 30px 0 0 0;
            font-size: 14px;
            line-height: 14px;
            letter-spacing: 1px;
            display: block;
        }
    }
    /*
    .smcx-embed {
        max-width: 100% !important;
        height: 100% !important;

        > .smcx-iframe-container {
            height: 100% !important;
            max-width: 100% !important;
        }
    }
        */
}

@-moz-keyframes scroll-left {
    0% {
        -moz-transform: translateX(100%);
    }

    100% {
        -moz-transform: translateX(-100%);
    }
}

@-webkit-keyframes scroll-left {
    0% {
        -webkit-transform: translateX(100%);
    }

    100% {
        -webkit-transform: translateX(-100%);
    }
}

@keyframes scroll-left {
    0% {
        -moz-transform: translateX(100%); /* Browser bug fix */
        -webkit-transform: translateX(100%); /* Browser bug fix */
        transform: translateX(100%);
    }

    100% {
        -moz-transform: translateX(-100%); /* Browser bug fix */
        -webkit-transform: translateX(-100%); /* Browser bug fix */
        transform: translateX(-100%);
    }
}
