@import "../../../Assets/scss/abstracts/variables";

.banner {
    width: 100%;
    min-height: 375px;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: cover;
    display: flex;
    position: relative;
    max-width: $pageWidth;
    margin: 0 auto 0 auto;
    align-items: center;

    .banner-content {
        width: calc(100% - 75px);
        //        height: calc(100% - 150px);
        min-height: 255px;
        margin: 75px 0 75px 75px;
        position: relative;
        display: flex;
        flex-direction: column;

        > div {
            width: 100%;
        }

        .bc-title {
            font-family: $font-bold;
            font-weight: 700;
            margin: 0 0 25px 0;
            font-size: 20px;
            line-height: 24px;
            width: 55%;
        }

        .bc-bottom {

            > div {
                width: 55%;

                &:last-child {
                    margin-bottom: 25px;
                }
            }

            .bc-topic {
                font-family: $font-bold;
                font-weight: 700;
                font-size: 40px;
                line-height: 44px;
            }

            .bc-bite {
                font-size: 32px;
                line-height: 36px;
                font-family: $font-light;
                font-weight: 300;
                margin: 10px 50px 0 50px;
            }
        }

        .bc-description {
            width: 700px;
            max-width: 50%;
            font-size: 20px;
            line-height: 24px;
            margin: auto 0 0 0;
        }
    }


    .banner-play {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 100px;
        height: 100px;

        svg {
            width: 100%;
            height: auto;
            cursor: pointer;
        }
    }

    .popup-overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(106,106,106, 0.85);
        z-index: 11;
        backdrop-filter: blur(3px);
        -webkit-backdrop-filte: blur(3px);
        display: flex;

        .popup {
            max-width: calc(100% - 40px);
            width: 844px;
            height: 476px;
            background-color: $themeBG;
            margin: auto;
            padding: 0px;
            position: relative;
            overflow: hidden;
            box-shadow: 0px 1px 21px 0 rgba(0, 0, 0, 0.25);
            border: 0.5px solid #888888;

            .icon-close-1 {
                position: absolute;
                top: 30px;
                right: 20px;
                width: 15px;
                height: 15px;
                cursor: pointer;

                .st0 {
                    stroke: #fff;
                }
            }

            iframe {
                width: 844px;
                height: 476px;
            }
        }

        &.active {
            display: flex;
        }
    }

    .banner-bar {
        width: 75px;
        height: 100%;
        position: relative;
        align-self: flex-start;
        margin-top: 2%;

        .bb-icons {
            width: 100%;
            bottom: inherit;
            top: 50%;
            right: 0;

            .bb-icon {

                &:not(:last-child) {
                    margin: 0 0 20px;
                }

                &.separator:after {
                    right: 25px;
                    left: 25px;
                    width: calc(100% - 50px);
                }

                svg {
                    width: calc(100% - 50px);
                    height: 100%;
                }

                &.active svg .st0 {
                    fill: #fff;
                    stroke: #fff;
                }
            }
        }
    }
}
