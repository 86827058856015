@import "../../../Assets/scss/abstracts/variables";


.feedback-container {
    display: flex;
    width: 0;
    height: 100%;
    position: fixed;
    z-index: 6;
    background-color: rgba(106,106,106,.85);
    top: 0;
    bottom: 0;
    right: 0;

    .invalid {
        border-color: #cc061f !important;
    }

    .sticky-feedback {
        position: fixed;
        right: 0;
        background-color: $themeBlue;
        width: 30px;
        height: 150px;
        padding: 35px 10px 35px 10px;
        top: 0;
        bottom: 0;
        margin: auto 0 auto 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        box-shadow: -6px 6px 12px #0D121952;
        color:#fff;

        svg {
            width: 100%;
            height: auto;
            margin: 6px 0 0 0;
        }

        span {
            writing-mode: vertical-lr;
            text-orientation: mixed;
            -moz-transform: scale(-1);
            -webkit-transform: scale(-1);
            -o-transform: scale(-1);
            -ms-transform: scale(-1);
            transform: scale(-1);
            font-size: 12px;
            line-height: 16px;
        }
    }

    .feedback.form {
        display: none;
    }

    &.active {
        width: 100%;
        left: 0;
        z-index: 11;
        justify-content: center;
        align-items: center;

        .sticky-feedback {
            display: none;
        }

        .feedback-form {
            display: flex;
            width: 400px;
            max-height: calc(100% - 50px);
            background-color: #fff;
            border: 0.5px solid #C6C6C6;
            flex-direction: column;

            .feedback-header {
                background-color: $themeBlue;
                padding: 30px 20px 20px 20px;
                position: relative;
                border-bottom: 4px solid $themeRed;
                color:#fff;

                .icon-close-1 {
                    width: 14px;
                    height: 14px;
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    cursor: pointer;

                    .st0 {
                        stroke: #c6c6c6;
                    }
                }

                .icon-feedback {
                    width: 52px;
                    height: auto;
                }

                div {
                    font-size: 20px;
                    line-height: 24px;
                    margin: 15px 0 0 0;
                }
            }

            .feedback-body {
                padding: 20px 20px 30px 20px;
                color: $themeBG;
                height: auto;
                overflow-y: auto;

                .feedback-question {
                    margin: 0 0 40px 0;

                    .f-question-label {
                        font-family: $font-bold;
                        margin: 0 0 20px 0;
                    }

                    .f-question.rating {
                        margin: 0 0 20px 0;
                    }

                    .f-question-textfield {
                        border-left: 1px solid #777777;
                        display: flex;
                        /*margin: 20px 0 0 0;*/
                        &.hidden {
                            display: none;
                        }

                        textarea {
                            resize: none;
                            border: 0;
                            width: calc(100% - 40px);
                            min-height: 100px;
                            padding: 10px 10px 10px 10px;
                            font-family: $font-regular;
                            font-size: 14px;
                            line-height: 18px;
                        }
                    }

                    .f-question-rating {
                        display: flex;
                        margin: 0 0 20px 0;

                        &.invalid .f-rate .icon-rating .a {
                            stroke: #cc061f !important;
                        }

                        .f-rate {
                            &:not(:last-child) {
                                margin: 0 12px 0 0;
                            }

                            svg {
                                width: 18px;
                                cursor: pointer;
                            }

                            &.selected svg .a {
                                fill: $themeRed;
                            }
                        }
                    }

                    .f-radio-buttons {
                        margin: 0 0 20px 0;
                        display: flex;

                        .f-radio-button {
                            display: flex;
                            font-size: 14px;
                            line-height: 20px;
                            flex-shrink: 0;
                            align-items: center;

                            &:not(:last-child) {
                                margin: 0 20px 0 0;
                            }

                            span {
                                width: 14px;
                                height: 14px;
                                border: 1px solid #777;
                                border-radius: 50%;
                                margin: 0 5px 3px 0;
                                cursor: pointer;
                                position: relative;
                                cursor: pointer;
                                flex-shrink: 0;
                            }

                            > p {
                            
                            }

                            &.active {
                                span:before {
                                    content: '';
                                    width: 12px;
                                    height: 12px;
                                    background-color: $themeBlue;
                                    border-radius: 50%;
                                    position: absolute;
                                    top: 0;
                                    bottom: 0;
                                    left: 0;
                                    right: 0;
                                    margin: auto;
                                }
                            }

                            .icon-arrow-right-1 {
                                height: 18px;
                                width: auto;
                                margin: 0 0 0 20px;
                                cursor: pointer;

                                .st0 {
                                    stroke: #777777;
                                }
                            }
                        }

                        &.isTextarea {
                            display: flex;
                            flex-direction: column;
                        }
                    }

                    .f-question-answer {
                        display: flex;
                        flex-direction: Column;

                        &:not(:last-child) {
                            margin: 0 0 15px 0;
                        }

                        .f-qa-question {
                            display: flex;
                            cursor: pointer;
                            align-items: center;


                            span {
                                width: 14px;
                                height: 14px;
                                border: 1px solid #777;
                                margin: 0 5px 0 0;
                                cursor: pointer;
                                position: relative;
                            }

                            .icon-arrow-right-1 {
                                height: 18px;
                                width: auto;
                                margin: 0 0 0 20px;

                                .st0 {
                                    stroke: #777777;
                                }
                            }

                            label {
                                display: flex;
                            }

                            > label.check-square {
                                input:checked {
                                    + span:before {
                                        content: '';
                                        width: 12px;
                                        height: 12px;
                                        background-color: $themeBlue;
                                        position: absolute;
                                        top: 0;
                                        bottom: 0;
                                        left: 0;
                                        right: 0;
                                        margin: auto;
                                    }
                                }
                            }
                        }

                        input:checked {
                            + div label:not(.check-square) {

                                span:before {
                                    content: '';
                                    width: 12px;
                                    height: 12px;
                                    background-color: $themeBlue;
                                    position: absolute;
                                    top: 0;
                                    bottom: 0;
                                    left: 0;
                                    right: 0;
                                    margin: auto;
                                }
                            }
                        }

                        .f-radio-buttons {
                            margin: 0;

                            .f-radio-button {
                                font-family: $font-bold;
                                span{margin: 0 5px 0 0;}
                            }

                            &.active {
                                flex-direction: column;

                                .f-radio-button {
                                    color: $themeBlue;

                                    .icon-arrow-right-1 {
                                        transform: rotate(90deg);
                                    }
                                }

                                .f-qa-answer {
                                    display: flex;
                                }
                            }
                        }
                    }



                    .f-qa-answer {
                        &:not(.f-question-textfield) {
                            display: none;
                        }

                        flex-direction: column;
                        margin: 12px 0 0 21px;
                        font-size: 14px;
                        line-height: 20px;

                        &.f-question-textfield {
                            margin: 20px 0 0 0;
                        }

                        p {
                            margin: 0 0 5px 0;
                        }
                    }
                }

                > p {
                    color: $themeRed;
                    font-family: $font-bold;
                    margin: 0 0 20px 0;
                }

                .button {
                    background-color: $themeBlue;
                    font-size: 14px;
                    line-height: 16px;
                    padding: 13px 25px 13px 25px;
                }
            }
        }
    }
}

textarea:focus {
    outline: none;
}

::-webkit-input-placeholder {
    font-family: $font-regular;
    font-size: 14px;
    line-height: 18px;
}

:-moz-placeholder {
    font-family: $font-regular;
    font-size: 14px;
    line-height: 18px;
}

::-moz-placeholder {
    font-family: $font-regular;
    font-size: 14px;
    line-height: 18px;
}

:-ms-input-placeholder {
    font-family: $font-regular;
    font-size: 14px;
    line-height: 18px;
}