@import "../../../../Assets/scss/abstracts/variables";

.alias-home {
    width: 100%;
    padding: 0 0 55px 0;

    .alias-home-body {
        max-width: 1400px;
        margin: 0 auto 40px auto;
        overflow: hidden;
        padding: 20px 0 25px 0;
    }

    > div:first-child {
        width: 100%;
    }

    .content-slider {
        width: calc(100% - 75px);
        height: 407px;
        position: relative;
        overflow: hidden;
        max-width: $pageWidth;
        margin: 90px auto 50px 75px;

        .slider {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;

            .slide-navigation {
                margin: 0 auto 20px 0;
                display: flex;
                justify-content: center;

                span {
                    width: 10px;
                    height: 10px;
                    border: 1px solid #fff;
                    border-radius: 50%;
                    cursor: pointer;
                    transition: background-color .3s;

                    &:not(:last-child) {
                        margin: 0 10px 0 0;
                    }

                    &.active {
                        background-color: #fff;
                        cursor: default;
                    }
                }
            }

            .slide-container {
                width: 100%;
                height: 322px;
                position: relative;

                .slide {
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    transition: opacity .3s;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: -1;
                    overflow: hidden;

                    &.active {
                        z-index: 0 !important;
                        opacity: 1 !important;
                    }

                    display: flex;

                    .slide-inner {
                        flex-grow: 1;
                        width: 50%;

                        &:last-child {
                            margin: 0 0 0 10px;

                            .btn {
                                left: 30px;
                            }
                        }

                        .tile {
                            width: 100%;

                            .content {
                                height: 100%;

                                .label {
                                    font-size: 18px;
                                    line-height: 38px;
                                    min-width: inherit;
                                    padding: 2px 15px 0 15px;
                                    height: 38px;
                                    display: inline-flex;
                                    align-items: center;
                                }

                                .tile-content {
                                    width: calc(100% - 120px);
                                    top: 60px;
                                    left: 30px;
                                    height: calc(100% - 90px);

                                    .t-content-top {
                                        .t-content-left {
                                            margin: 0 30px 0 0;

                                            .t-image {
                                                width: 160px;
                                                height: 142px;

                                                img {
                                                    width: calc(100% - 40px);
                                                }
                                            }

                                            .t-category {
                                                height: 30px;
                                                width: 162px;
                                                font-size: 18px;
                                                line-height: 32px;
                                            }
                                        }

                                        .t-content-right {
                                            height: 176px;
                                            overflow: hidden;

                                            > div {
                                                line-height: 21px;
                                                font-size: 18px;

                                                &:first-child {
                                                    font-family: $font-bold;
                                                }

                                                &:last-child {
                                                    font-family: $font-regular;
                                                    font-weight: normal;
                                                    margin-top: 10px;
                                                }
                                            }
                                        }
                                    }

                                    .t-content-bottom {
                                        margin: auto 0 0 192px;

                                        .t-media {
                                            width: 33px;
                                            height: 29px;

                                            svg {
                                                width: 100%;
                                                height: auto;
                                            }
                                        }
                                    }
                                }
                            }

                            .bb-icons {
                                height: calc(100% - 30px);
                                width: 60px;
                                right: 30px;
                                top: 15px;

                                .bb-icon {
                                    display: flex;
                                    align-items: center;

                                    svg {
                                        margin: 0 0 6px 0;

                                        &.icon-favorite {
                                            width: 24px;
                                        }

                                        &.icon-save {
                                            width: 33px;
                                        }

                                        &.icon-like {
                                            width: 33px;
                                        }

                                        &.icon-rate {
                                            width: 35px;
                                        }

                                        &.icon-share {
                                            width: 29px;
                                        }
                                    }
                                }

                                .bb-icon:not(:last-child) {
                                    padding: 0px 0 7px 0;

                                    &.separator {
                                        margin: 0 0 7px 0;

                                        &:after {
                                            width: 26px;
                                            margin: auto;
                                        }
                                    }
                                }

                                div p {
                                    opacity: 1;
                                    font-size: 10px;
                                    line-height: 12px;
                                    max-height: inherit;
                                }
                            }

                            .t-embed,
                            .progress {
                                display: none;
                            }
                        }


                        .slide-image {
                            background-repeat: no-repeat;
                            background-size: cover;
                            background-position: left center;
                            width: 100%;
                            height: 100%;
                            position: relative;

                            .label {
                                position: absolute;
                                top: 0;
                                right: 0;
                                padding: 8px 15px;
                                font-size: 12px;
                                line-height: 10px;
                                letter-spacing: 1px;
                                text-align: center;
                                min-width: 70px;
                                color: $primary-color;

                                &.fresh {
                                    background-color: #2E61B2;
                                }

                                &.hot {
                                    background-color: #D20423;
                                }
                            }
                        }

                        .btn {
                            height: 28px;
                            text-align: center;
                            width: auto;
                            padding: 2px 20px 0 20px;
                            font-family: $font-bold;
                            font-weight: 700;
                            cursor: pointer;
                            background-color: $themeBlue;
                            left: 73px;
                            bottom: 55px;
                            display: inline-flex;
                            align-items: center;
                        }
                    }
                }
            }
        }
    }

    hr {
        margin: 8px 0;
    }
}
