$pageWidth:1400px;

$primary-color: var(--mainColor);
$secondary-color: var(--secColor);
$themeBG: var(--themeBG);
$overlayBG: var(--overlayBG);
$font-regular: var(--mainFont);
$font-bold: var(--boldFont);
$font-light: var(--lightFont);

$slideInterval: var(--slideInterval);
$slideSpeed: .5s;

$themeBlue: #2E61B2;
$themeRed: #D20423;