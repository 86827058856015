@import "../../../../Assets/scss/abstracts/variables";

.alias-topic .page-body {
    .p-overview .text {
        width: 100%;

        > p:first-child {
            font-family: $font-bold;
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            width: 100%;
        }

        > p:last-child {
            font-family: $font-light;
            font-weight: 300;
            margin: 20px 0 0 0;
            font-size: 18px;
            line-height: 24px;
            width: 100%;
        }
    }

    .p-content .topic-container {
        width: 100%;
        position: relative;
        min-height: 378px;

        .topic {
            width: 100%;
            height: 160px;
            display: flex;
            position: relative;

            &:not(:first-child) {
                margin: 30px 0 0 0;
            }

            > div:not(.loader-container):not(.tile) {
                width: calc((100% - 354px) / 2);
            }

            .tile {
                width: 314px;

                &.fixed .tile-inner {
                    height: 130%;
                }
            }

            .bite-text {
                font-size: 15px;
                line-height: 18px;
                margin: 0 20px 0 20px;
                display: flex;
                flex-direction: column;

                .subtext {
                    font-family: $font-light;
                    font-weight: 300;
                    margin: 0 0 10px 0;
                    overflow-x: hidden;
                    overflow-y: auto;
                }
            }
        }
    }
}
