.content-main > .grow {
    &:not(.alias-home), &.alias-home .alias-home-body {
        padding: 20px 0 530px 0;
    }
}

.progress {
    width: 100%;
    border-radius: 0!important;
    .bar {
        width: 100%;
        background-color: #575757;
        position: relative;
        display: flex;

        &:before {
            content: '';
            background-color: #2E61B2;
            height: 100%;
            border-right: 1px solid #fff;
        }

        &:after {
            position: absolute;
            top: 100%;
            font-size: 12px;
            line-height: 16px;
            font-family: $font-light;
            font-weight: 300;
        }

        &.p-0:before {
            border-right: none;
        }
    }

    @for $i from 0 through 100 {
        .p-#{$i}::before {
            width: $i * 1%
        }
    }

    @for $i from 1 through 7 {
        .p-#{$i}::after {
            left: 0;
            content: $i +'%';
        }
    }

    @for $i from 8 through 100 {
        .p-#{$i}::after {
            right: calc(100% - #{$i} * 1%);
            content: $i +'%';
        }
    }
}

.alias-topic, .alias-bite {
    width: 100%;
    padding: 0 0 55px 0;
    max-width: 1400px;
    margin: 0 auto;
    overflow: hidden;

    .page-body {
        width: calc(100% - 150px);
        padding: 40px 75px 50px 75px;
        min-height: 500px;
        display: flex;
        position: relative;
        max-width: $pageWidth;
        margin: 20px auto 0 auto;

        .p-overview, .p-content {
            > .bar {
                background-color: #ccc;


                &:before {
                    position: absolute;
                    top: calc(100% + 1px);
                    height: 3px;
                }

                &:after {
                    display: none;
                }
            }

            > p {
                margin: 10px 0 85px;
                letter-spacing: 1px;
            }
        }

        .p-overview {
            width: 25%;

            .bar {
                width: 60%;
                height: 7px;
            }
        }

        .p-content {
            width: calc(75% - 50px);
            margin: 0 0 0 50px;

            .bar {
                width: 100%;
                height: 5px;
            }

            > p {
                margin: 12px 0 85px;
                letter-spacing: 1px;
            }
        }
    }

    .bite-quizz {
        position: relative;
        font-size: 15px;
        line-height: 20px;

        .bq-title {
            width: 100%;
            border-bottom: 2px solid rgba(255,255,255,.8);
            margin: 0 0 20px;
            padding: 0 0 10px;
            font-family: $font-bold;
            font-weight: 700;
        }

        .bq-element {
            width: 100%;
            border-bottom: 1px solid rgba(255,255,255,.25);
            margin: 0 0 5px;
            padding: 0 0 5px;
            display: flex;
            justify-content: space-between;

            svg {
                height: 24px;
                width: auto;
            }

            span {
                width: auto;
            }
        }

        &:not(.topics) {
            line-height: 24px;

            .bq-element {
                justify-content: inherit;

                .bq-icon {
                    height: 24px;
                    width: 30px;

                    svg {
                        height: 100%;
                        width: auto;
                    }
                }

                span:last-child {
                    margin: 0 0 0 auto;
                }
            }
        }
    }
}