@import "../../../Assets/scss/abstracts/variables";

.bb-icons {
    width: 15px;
    position: absolute;
    right: 10px;
    margin: auto;
    top: 10px;
    text-align: center;
    bottom: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .separator {
        position: relative;
        padding: 0 0 20px;

        &:after {
            content: '';
            position: absolute;
            /*top: 0;
            right: 0;*/
            bottom: 0;
            width: 100%;
            height: 1px;
            background-color: #fff;            
            right: 0;
            left:0;
            width: 100%;
        }
    }

    .bb-icon {
        width: 100%;
        height: auto;
        flex-direction: column;

        svg {
            height: auto;

            &.icon-favorite, &.icon-like {
                cursor: pointer;
            }
        }

         p {
            width: 100%;
            text-align: center;
            font-size: 8px;
            line-height: 8px;
            font-family: $font-bold;
            font-weight: 700;

            &:not(.val) {
                margin: 6px 0 0;
                color: #4464AA;
            }

            &.val {
                margin: 5px 0 0;
            }
        }
    }
}