@import "../../../Assets/scss/abstracts/variables";

.embed {
    position: absolute;
    top: calc(100% - 9px);
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.75);
    left: 0;
    right: 0;
    height: auto;
    background-color: $themeBG;
    width: 100%;
    max-height: 0;
    transition: max-height .3s;
    overflow: hidden;
    z-index: 2;

    .embed-inner {
        width: calc(100% - 50px);
        padding: 15px 25px;
        min-height: 300px;
    }

    > div {
        width: 100%;
    }

    .e-top {
        font-family: $font-light;
        font-weight: 300;
        display: flex;

        div {
            cursor: pointer;
            width: 15px;
            height: 15px;
            align-self: center;
            margin: 0 0 0 auto;

            .st0 {
                stroke: #fff;
            }
        }
    }

    .e-title {
        margin: 25px 0 0 0;
        font-size: 12px;
        line-height: 16px;
        font-family: $font-bold;
        font-weight: 700;
        text-align: center;
    }

    .e-model {
        margin: 15px 0 0 0;
        width: 100%;
        position: relative;

        .slide-container, .model4-container-inner {
            > svg:first-child {
                display: none;
            }

            .model.model4 {
                position: relative !important;
                width: 100% !important;
                left: 0 !important;
                top: 0 !important;
            }
        }

        .icon-zoom, .close-zoom {
            display: none;
        }

        .model4-container-inner .model.model4 {
            position: absolute;
            left: 9px;
            right: 0;
            top: -3px;
            width: calc(100% - 25px);
        }
    }

    .e-bottom {
        margin: 15px 0 0 0;
        font-size: 10px;
        line-height: 12px;
        color: rgba(255, 255, 255, 0.75);
        display: flex;

        .e-text {
            width: calc((100% - 40px) / 3);
            transition: color .3s;

            &.active {
                color: rgba(204, 6, 31, 0.75);
            }

            &:nth-child(2) {
                width: calc((100% - 40px) / 3);
                border-left: 1px solid rgba(255, 255, 255, 0.75);
                border-right: 1px solid rgba(255, 255, 255, 0.75);
                margin: 0 10px;
                padding: 0 10px;
            }
        }
    }
}


.context-open {

    &.alias-home {
        .model-carousel, .content-slider .slider .slide, .tile-inner {
            &:before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                
                z-index: 1;
            }
        }

        .model-carousel:before {
            z-index: 4;
        }
    }

    .tile-inner {
        &:before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
        }
    }

    .tile-content {
        .shadow-tile, .arrow {
            z-index: 0 !important;
        }
    }

    &.alias-topic .page-body .p-content .topic-container .topic.active-bite{
        z-index:2;
    }
}
