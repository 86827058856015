@import "../../../Assets/scss/abstracts/variables";

.load-more-container {
    display: flex;
    justify-content: center;

    .load-more {
        padding: 7px 20px;
        background-color: rgba(0,0,0,.7);
        display: flex;
        flex-direction: column;
        cursor: pointer;
        min-width: 115px;
        text-align: center;
        font-family: $font-bold;
        font-size: 14px;
        line-height: 16px;

        svg {
            width: 15px;
            height: 15px;
            margin: 5px auto 2px auto;
        }

        &.loading {
            cursor: default;

            svg {
                -webkit-animation: spin 2s linear infinite;
                -moz-animation: spin 2s linear infinite;
                animation: spin 2s linear infinite;
            }
        }
    }
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
