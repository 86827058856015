/*login*/
.auth-container {
    width: 100%;
    /*min-height: 1250px;*/
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 18px;
    padding: 0 0 100px 0;

    .header {
        height: 70px;
        /*background-color: #fff;*/
        padding: 30px 75px 30px 75px;
        display: flex;
        justify-content: space-between;
        
        .logo {
            height: 100%;
            width: auto;
        }

        .action-buttons {
            display: flex;
            justify-content: flex-end;
            height: 42px;
            align-self: flex-end;
            display:none;

            .btn {
                height: 16px;
                padding: 13px 40px 13px 40px;
                background-color: #B50A21;
                cursor: pointer;
                @extend %font-bold;
            }

            .login-btn {
                margin: 0 0 0 25px;
                border-left: 1px solid rgba(0,0,0,.6);
                color: #000;
                height: 25px;
                padding: 8px 0 9px 0;
                display: flex;

                svg {
                    width: 18px;
                    height: 25px;
                    margin: 0 15px 0 25px;
                }

                div {
                    @extend %font-bold;
                    cursor: pointer;
                    align-self: center;
                }
            }
        }
    }

    .auth-actions {
        width: auto;
        height: auto;
        margin: 200px auto 0 auto;
        padding: 40px 35px 25px 35px;
        background-color: #336FB7;
        position: relative;
        box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.5);
        display: none;

        &.active {
            display: block;
        }

        .icon-close-1 {
            position: absolute;
            top: 25px;
            right: 25px;
            width: 24px;
            height: 24px;
            cursor: pointer;

            .st0 {
                stroke: #fff;
            }
        }

        .aa-content {
            min-height: 100%;
            display: flex;
            flex-direction: column;
            width: 100%;

            .title {
                width: 100%;
                margin: 0 0 45px 0;
                @extend %font-light;
                font-size: 30px;
                line-height: 36px;
            }

            .text {
                text-align: center;
                margin: 0 15px 20px 15px;
            }

            input {
                margin: 10px 0 10px 0;
                background-color: transparent;
                border-bottom: 1px solid #fff;
                color: #fff;
                font-size: 14px;
                line-height: 18px;
                padding: 0 0 5px 0;

                &::placeholder {
                    color: rgba(255,255,255,.5);
                }
            }

            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            input:-webkit-autofill:active {
                -webkit-box-shadow: 0 0 0 30px #336FB7 inset !important;
                -webkit-text-fill-color: #fff !important;
            }
            /*&.invalid::placeholder {
                    color: #cc061f;
                }*/
            .check-container {
                display: flex;
                margin: 0 0 10px 0;

                label {
                    width: 17px;
                    height: 17px;
                    margin-right: 10px;

                    span {
                        width: 16px;
                        height: 16px;
                        border: 1px solid #fff;
                        cursor: pointer;
                        position: relative;
                        display: flex;

                        &:before {
                            content: '\2713';
                            position: absolute;
                            top: 0;
                            right: 0;
                            width: 13px;
                            height: 9px;
                            font-size: 15px;
                            opacity: 0;
                            transition: opacity .3s;
                        }
                    }

                    input:checked + span:before, &:hover span:before {
                        opacity: 1;
                    }
                }

                p {
                    font-size: 12px;
                    @extend %font-light;

                    a {
                        @extend %font-light;
                        border-bottom: 1px solid #fff;
                        cursor: pointer;
                    }
                }

                &.invalid {
                    label span, p > a {
                        border-color: #cc061f;
                    }
                }
            }

            .aa-block {
                display: flex;
                margin: 0 0 45px 0;

                .aa-left {
                    width: calc(100% / 3);
                    @extend %font-bold;
                }

                .aa-right {
                    display: flex;
                    flex-direction: column;
                    width: calc(100% / 3 * 2);

                    > input:first-child {
                        margin: 0 0 10px 0;
                    }
                }
            }

            .button-container {
                margin: auto 0 0 0;
                padding: 30px 0 0 0;
                display: flex;
                justify-content: center;

                .bc-btn {
                    padding: 13px 45px;
                    cursor: pointer;
                    @extend %font-bold;
                    padding: 13px 40px 13px 40px;
                    background-color: #B50A21;
                    height: 16px;
                }
            }

            .note {
                margin: 10px auto 0;
                color: rgba(255,255,255,.5);
                transition: color .3s;

                &.link {
                    cursor: pointer;

                    &:hover {
                        color: #fff;
                    }
                }
            }

            .invalid {
                border-color: #cc061f;
                /*color: #cc061f;*/
            }
        }

        .login {
            width: 300px;
            height: 100%;

            .l-process {
                .forgot-action {
                    margin: 0 0 0 auto;
                    color: rgba(255,255,255,.5);
                    cursor: pointer;
                    transition: color .3s;

                    &:hover {
                        color: rgba(255,255,255,1);
                    }
                }

                > .note:not(.link) {
                    color: #cc061f;
                    margin: 30px auto 0;
                }
            }

            .l-forgot {
                display: none;
            }

            > input:checked + .l-process {
                display: none !important;

                + .l-forgot {
                    display: flex;
                }
            }
        }

        .register {
            display: none;
            width: 450px;
            height: 100%;

            .r-process {
                .password-strength {
                    width: 100%;
                    position: relative;

                    &.invalid {
                        input, input::placeholder {
                            border-color: #cc061f;
                            /*color: #cc061f;*/
                        }
                        /*.-strength-desc {
                            color: #cc061f;
                        }*/
                    }

                    > input {
                        width: 100%;
                    }

                    .-strength-desc {
                        position: absolute;
                        top: 10px;
                        right: 0;
                        color: rgba(255,255,255,.5);
                    }
                }
            }

            .r-confirmation {
                display: none;
            }

            .r-process.hide {
                display: none !important;
            }

            .r-confirmation.show {
                display: flex;

                .successfull {
                    color: #70bd4f;
                    @extend %font-bold;
                }

                &.invalid {
                    .successfull {
                        color: #cc061f;
                    }

                    .bc-btn {
                        border-color: rgba(255,255,255,.5);
                        color: rgba(255,255,255,.5);
                        cursor: default;
                    }
                }
            }
        }

        > input:checked {
            + .login {
                display: none;
            }

            + div + .register {
                display: flex;
            }
        }
    }
}



.react-datepicker__input-container input {
    display: block !important;
    width: 100% !important;
}