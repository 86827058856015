@import '../../../Assets/scss/abstracts/variables';

.breadcrumbsNav {
  display: flex;
  margin: 50px auto 10px auto;
  padding: 0 0 10px 75px;
  font-family: $font-bold;
  line-height: 1;

  &-inner {
    display: flex;
    flex-direction: column;
  }

  &-label {
    display: flex;
    width: 966px;
    margin: 0 auto 10px;
    min-height: 16px;
    font-size: 16px;

    &-item {
      padding: 0 0 0 15px;
      width: 100%;
      font-family: $font-bold;
      font-weight: 700;

      &:nth-child(2) {
        color: $themeRed;
      }

      &:last-child {
        color: $themeBlue;
      }
    }
  }

  &-path {
    margin: 0 auto 0 auto;
    width: 966px;
    max-width: 100%;
    font-size: 14px;

    &-container {
      box-sizing: border-box;
      z-index: 3;
      background-color: #ffffff;

      .darkMode & {
        background-color: rgba(40,40,40,.6);
      }
    }

    &-inner {
      display: flex;
      width: 100%;
    }

    &-section {
      display: flex;
      flex-direction: column;
      margin: 8px 8px 8px 0;
      width: 100%;
      height: auto;

      &:not(:nth-child(1)) {
        > .breadcrumbsNav-path-section-inner {
          border-left: 1px solid #000000;

          .darkMode & {
            border-left: 1px solid #ffffff;
          }
        }
      }
    }

    &-section-inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow-y: auto;
      padding: 6px 0 6px 15px;
      width: calc(100% - 20px);
      height: 100%;

      a {
        color: #335f9b;

        &:hover {
          text-decoration: underline;
        }
      }

      p {
        margin: 0;
      }
    }
  }
}
