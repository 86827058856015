@import "../../../Assets/scss/abstracts/variables";

.shadow-tile {
    position: absolute;
    z-index: 4;

    &.left {
        right: calc(100% - 75px);
        top: 0;

        svg {
            transform: scaleX(-1);
        }
    }

    &.right {
        left: calc(100% - 75px);
        bottom: 0;
    }
}
